<template>
    <div style="overflow-x:auto !important">
        <div v-if="za_employee == false && ((Object.keys(this.summary).length > 0))" style="display: grid; grid-template-columns: auto 400px; grid-column-gap: 50px;">
            <div>
                <div v-if="summary.elevatorMotor_Machine != null">
                    <div class="ZAfont_family my_color_00338e" style="font-weight: 600; font-size: 1.5rem; display: grid; grid-template-columns: auto auto; grid-column-gap: 5px;">
                        <div style="text-align:left; float:left">{{$t('3024')}} </div>
                    </div>
                    <hr style="margin-top:0px" />
                    <div>
                        <div style="display: grid; grid-template-columns: 150px auto; grid-column-gap: 5px;">
                            <div>
                                <Images :imageFilePath="summary.elevatorMotor_Machine_ImagePath" :maxImageHeight="80" :maxImageWidth="120"></Images>
                            </div>
                            <div>
                                <div style="display: grid; grid-template-columns: auto auto; grid-column-gap: 5px;">
                                    <div>
                                        <div>
                                            <div>
                                                <span class="my_color_00338e ZAfont_family" style="font-weight:600; font-size:18px">{{$t('3027')}}</span>
                                            </div>
                                            <!--<div style="max-width: 275px; text-align: right">
                                    <div class="ZAfont_family" style="font-weight:600; color:#000000; font-size:24px">XXX,XX €</div>
                                    <a class="ZAfont_family" href="#">{{$t('3196')}}</a>
                                    </div>-->
                                        </div>
                                        <div>
                                            <div style="display:grid; grid-template-columns:15px auto; grid-gap:5px; align-items:center">
                                                <div>
                                                    <span class="fas fa-plus my_color_00338e"></span>
                                                </div>
                                                <div class="ZAfont_family ZAfont_size">
                                                    <span class="my_color_00338e" style="font-weight: 600; font-size: 18px"> {{$t('3028')}}:</span>
                                                    <span style="font-weight:600; color:#000000; font-size:20px"> {{summary.elevatorMotor_Machine.typeDesignation}}</span>
                                                </div>
                                                <div class="ZAfont_family ZAfont_size" style="grid-column: 2 / span 1">
                                                    <span>{{$t('410')}}: {{summary.elevatorMotor_Machine.gearRatio1}}:{{summary.elevatorMotor_Machine.gearRatio2}}</span>
                                                    <span> | </span>
                                                    <span>{{$t('3048')}}: {{summary.elevatorMotor_Machine.maximumAxleLoad}} kg</span>
                                                    <span> | </span>
                                                    <span>{{$t('1091')}}: {{summary.elevatorMotor_Machine.nominalRotationSpeed}} rpm</span>
                                                    <span> | </span>
                                                    <span>{{$t('3049')}}: {{ValueDecimalSeparator(summary.elevatorMotor_Machine.operatingCurrent)}} A</span>
                                                    <span> | </span>
                                                    <span>{{$t('3051')}}: {{ValueDecimalSeparator(summary.elevatorMotor_Machine.operatingTorque)}} Nm</span>
                                                    <span> | </span>
                                                    <span>{{$t('1372')}}: {{ValueDecimalSeparator(summary.elevatorSystems.dutyCycle)}} %</span>
                                                    <span> | </span>
                                                    <span>{{$t('1092')}}: {{ValueDecimalSeparator(summary.elevatorSystems.startsPerHour)}}</span>
                                                    <span> | </span>
                                                    <span>{{$t('3050')}}: {{ValueDecimalSeparator(summary.elevatorMotor_Machine.electricalUtilization)}} %</span>
                                                    <span> | </span>
                                                    <span>{{$t('3052')}}: {{ValueDecimalSeparator(summary.elevatorMotor_Machine.mechanicalUtilization)}} %</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <hr />
                                    <div style="display:grid; grid-template-columns:auto auto;grid-gap:5px">
                                        <div>
                                            <div style="display:grid; grid-template-columns:15px auto; grid-gap:5px; align-items:center">
                                                <div>
                                                    <i class="fas fa-plus my_color_00338e"></i>
                                                </div>
                                                <div>
                                                    <span class="my_color_00338e ZAfont_family" style="font-weight: 600; font-size: 18px"> {{$t('1301')}}:</span>
                                                    <span v-if="((summary.suspensionMeans.ropeDiameter != summary.suspensionMeans.ropeDiameter2) && (summary.elevatorMotor_TractionSheave.sheaveProfile=='circular undercut groove')&&(summary.elevatorMotor_TractionSheave.sheaveHardened==false))" class="ZAfont_family" style="font-weight:600; color:#000000; font-size:20px"> {{summary.elevatorMotor_TractionSheave.diameter}}/{{summary.elevatorMotor_TractionSheave.sheaveWidth}}/{{summary.elevatorMotor_TractionSheave.grooveDistance}}/{{summary.elevatorMotor_TractionSheave.numberOfGrooves}}x{{ValueDecimalSeparator(summary.suspensionMeans.ropeDiameter2)}} ({{ValueDecimalSeparator(summary.suspensionMeans.ropeDiameter)}})/U{{summary.elevatorMotor_TractionSheave.undercutAngle}}</span>
                                                    <span v-if="((summary.suspensionMeans.ropeDiameter != summary.suspensionMeans.ropeDiameter2) && (summary.elevatorMotor_TractionSheave.sheaveProfile=='V-groove with undercut')&&(summary.elevatorMotor_TractionSheave.sheaveHardened==false))" class="ZAfont_family" style="font-weight:600; color:#000000; font-size:20px"> {{summary.elevatorMotor_TractionSheave.diameter}}/{{summary.elevatorMotor_TractionSheave.sheaveWidth}}/{{summary.elevatorMotor_TractionSheave.grooveDistance}}/{{summary.elevatorMotor_TractionSheave.numberOfGrooves}}x{{ValueDecimalSeparator(summary.suspensionMeans.ropeDiameter2)}} ({{ValueDecimalSeparator(summary.suspensionMeans.ropeDiameter)}})/K{{summary.elevatorMotor_TractionSheave.vgrooveAngle}}</span>
                                                    <span v-if="((summary.elevatorMotor_TractionSheave.sheaveProfile=='belt')&&(summary.elevatorMotor_TractionSheave.sheaveHardened==false))" class="ZAfont_family" style="font-weight:600; color:#000000; font-size:20px"> {{summary.elevatorMotor_TractionSheave.diameter}}/{{summary.elevatorMotor_TractionSheave.sheaveWidth}}/{{summary.elevatorMotor_TractionSheave.grooveDistance}}/{{summary.elevatorMotor_TractionSheave.numberOfGrooves}}x{{ValueDecimalSeparator(summary.suspensionMeans.cordDiameter)}}x{{ValueDecimalSeparator(summary.suspensionMeans.beltWidth)}}/R</span>
                                                    <span v-if="((summary.suspensionMeans.ropeDiameter != summary.suspensionMeans.ropeDiameter2) && (summary.elevatorMotor_TractionSheave.sheaveProfile=='circular groove')&&(summary.elevatorMotor_TractionSheave.sheaveHardened==false))" class="ZAfont_family" style="font-weight:600; color:#000000; font-size:20px"> {{summary.elevatorMotor_TractionSheave.diameter}}/{{summary.elevatorMotor_TractionSheave.sheaveWidth}}/{{summary.elevatorMotor_TractionSheave.grooveDistance}}/{{summary.elevatorMotor_TractionSheave.numberOfGrooves}}x{{ValueDecimalSeparator(summary.suspensionMeans.ropeDiameter2)}} ({{ValueDecimalSeparator(summary.suspensionMeans.ropeDiameter)}})/R</span>
                                                    <span v-if="((summary.suspensionMeans.ropeDiameter != summary.suspensionMeans.ropeDiameter2) && (summary.elevatorMotor_TractionSheave.sheaveProfile=='V-groove')&&(summary.elevatorMotor_TractionSheave.sheaveHardened==true))" class="ZAfont_family" style="font-weight:600; color:#000000; font-size:20px"> {{summary.elevatorMotor_TractionSheave.diameter}}/{{summary.elevatorMotor_TractionSheave.sheaveWidth}}/{{summary.elevatorMotor_TractionSheave.grooveDistance}}/{{summary.elevatorMotor_TractionSheave.numberOfGrooves}}x{{ValueDecimalSeparator(summary.suspensionMeans.ropeDiameter2)}} ({{ValueDecimalSeparator(summary.suspensionMeans.ropeDiameter)}})/HK{{summary.elevatorMotor_TractionSheave.vgrooveAngle}}</span>
                                                    <span v-if="((summary.suspensionMeans.ropeDiameter != summary.suspensionMeans.ropeDiameter2) && (summary.elevatorMotor_TractionSheave.sheaveProfile=='circular undercut groove')&&(summary.elevatorMotor_TractionSheave.sheaveHardened==true))" class="ZAfont_family" style="font-weight:600; color:#000000; font-size:20px"> {{summary.elevatorMotor_TractionSheave.diameter}}/{{summary.elevatorMotor_TractionSheave.sheaveWidth}}/{{summary.elevatorMotor_TractionSheave.grooveDistance}}/{{summary.elevatorMotor_TractionSheave.numberOfGrooves}}x{{ValueDecimalSeparator(summary.suspensionMeans.ropeDiameter2)}} ({{ValueDecimalSeparator(summary.suspensionMeans.ropeDiameter)}})/HU{{summary.elevatorMotor_TractionSheave.undercutAngle}}</span>
                                                    <span v-if="((summary.suspensionMeans.ropeDiameter != summary.suspensionMeans.ropeDiameter2) && (summary.elevatorMotor_TractionSheave.sheaveProfile=='V-groove with undercut')&&(summary.elevatorMotor_TractionSheave.sheaveHardened==true))" class="ZAfont_family" style="font-weight:600; color:#000000; font-size:20px"> {{summary.elevatorMotor_TractionSheave.diameter}}/{{summary.elevatorMotor_TractionSheave.sheaveWidth}}/{{summary.elevatorMotor_TractionSheave.grooveDistance}}/{{summary.elevatorMotor_TractionSheave.numberOfGrooves}}x{{ValueDecimalSeparator(summary.suspensionMeans.ropeDiameter2)}} ({{ValueDecimalSeparator(summary.suspensionMeans.ropeDiameter)}})/K{{summary.elevatorMotor_TractionSheave.vgrooveAngle}}U{{summary.elevatorMotor_TractionSheave.undercutAngle}}</span>

                                                    <span v-if="((summary.suspensionMeans.ropeDiameter == summary.suspensionMeans.ropeDiameter2) && (summary.elevatorMotor_TractionSheave.sheaveProfile=='circular undercut groove')&&(summary.elevatorMotor_TractionSheave.sheaveHardened==false))" class="ZAfont_family" style="font-weight:600; color:#000000; font-size:20px"> {{summary.elevatorMotor_TractionSheave.diameter}}/{{summary.elevatorMotor_TractionSheave.sheaveWidth}}/{{summary.elevatorMotor_TractionSheave.grooveDistance}}/{{summary.elevatorMotor_TractionSheave.numberOfGrooves}}x{{ValueDecimalSeparator(summary.suspensionMeans.ropeDiameter)}}/U{{summary.elevatorMotor_TractionSheave.undercutAngle}}</span>
                                                    <span v-if="((summary.suspensionMeans.ropeDiameter == summary.suspensionMeans.ropeDiameter2) && (summary.elevatorMotor_TractionSheave.sheaveProfile=='V-groove with undercut')&&(summary.elevatorMotor_TractionSheave.sheaveHardened==false))" class="ZAfont_family" style="font-weight:600; color:#000000; font-size:20px"> {{summary.elevatorMotor_TractionSheave.diameter}}/{{summary.elevatorMotor_TractionSheave.sheaveWidth}}/{{summary.elevatorMotor_TractionSheave.grooveDistance}}/{{summary.elevatorMotor_TractionSheave.numberOfGrooves}}x{{ValueDecimalSeparator(summary.suspensionMeans.ropeDiameter)}}/K{{summary.elevatorMotor_TractionSheave.vgrooveAngle}}</span>
                                                    <span v-if="((summary.suspensionMeans.ropeDiameter == summary.suspensionMeans.ropeDiameter2) && (summary.elevatorMotor_TractionSheave.sheaveProfile=='circular groove')&&(summary.elevatorMotor_TractionSheave.sheaveHardened==false))" class="ZAfont_family" style="font-weight:600; color:#000000; font-size:20px"> {{summary.elevatorMotor_TractionSheave.diameter}}/{{summary.elevatorMotor_TractionSheave.sheaveWidth}}/{{summary.elevatorMotor_TractionSheave.grooveDistance}}/{{summary.elevatorMotor_TractionSheave.numberOfGrooves}}x{{ValueDecimalSeparator(summary.suspensionMeans.ropeDiameter)}}/R</span>
                                                    <span v-if="((summary.suspensionMeans.ropeDiameter == summary.suspensionMeans.ropeDiameter2) && (summary.elevatorMotor_TractionSheave.sheaveProfile=='V-groove')&&(summary.elevatorMotor_TractionSheave.sheaveHardened==true))" class="ZAfont_family" style="font-weight:600; color:#000000; font-size:20px"> {{summary.elevatorMotor_TractionSheave.diameter}}/{{summary.elevatorMotor_TractionSheave.sheaveWidth}}/{{summary.elevatorMotor_TractionSheave.grooveDistance}}/{{summary.elevatorMotor_TractionSheave.numberOfGrooves}}x{{ValueDecimalSeparator(summary.suspensionMeans.ropeDiameter)}}/HK{{summary.elevatorMotor_TractionSheave.vgrooveAngle}}</span>
                                                    <span v-if="((summary.suspensionMeans.ropeDiameter == summary.suspensionMeans.ropeDiameter2) && (summary.elevatorMotor_TractionSheave.sheaveProfile=='circular undercut groove')&&(summary.elevatorMotor_TractionSheave.sheaveHardened==true))" class="ZAfont_family" style="font-weight:600; color:#000000; font-size:20px"> {{summary.elevatorMotor_TractionSheave.diameter}}/{{summary.elevatorMotor_TractionSheave.sheaveWidth}}/{{summary.elevatorMotor_TractionSheave.grooveDistance}}/{{summary.elevatorMotor_TractionSheave.numberOfGrooves}}x{{ValueDecimalSeparator(summary.suspensionMeans.ropeDiameter)}}/HU{{summary.elevatorMotor_TractionSheave.undercutAngle}}</span>
                                                    <span v-if="((summary.suspensionMeans.ropeDiameter == summary.suspensionMeans.ropeDiameter2) && (summary.elevatorMotor_TractionSheave.sheaveProfile=='V-groove with undercut')&&(summary.elevatorMotor_TractionSheave.sheaveHardened==true))" class="ZAfont_family" style="font-weight:600; color:#000000; font-size:20px"> {{summary.elevatorMotor_TractionSheave.diameter}}/{{summary.elevatorMotor_TractionSheave.sheaveWidth}}/{{summary.elevatorMotor_TractionSheave.grooveDistance}}/{{summary.elevatorMotor_TractionSheave.numberOfGrooves}}x{{ValueDecimalSeparator(summary.suspensionMeans.ropeDiameter)}}/K{{summary.elevatorMotor_TractionSheave.vgrooveAngle}}U{{summary.elevatorMotor_TractionSheave.undercutAngle}}</span>
                                                </div>
                                                <div class="ZAfont_family ZAfont_size" style="grid-column: 2 / span 1">
                                                    <span>{{$t('3038')}}: {{summary.elevatorMotor_TractionSheave.diameter}} mm</span>
                                                    <span> | </span>
                                                    <span>{{$t('1182')}}: {{summary.elevatorMotor_TractionSheave.sheaveWidth}} mm</span>
                                                    <span> | </span>
                                                    <span>{{$t('294')}}: {{summary.elevatorMotor_TractionSheave.grooveDistance}} mm</span>
                                                    <span> | </span>
                                                    <span>{{$t('165')}}: {{summary.elevatorMotor_TractionSheave.numberOfGrooves}}</span>
                                                    <span v-if="((summary.suspensionMeans.ropeDiameter != summary.suspensionMeans.ropeDiameter2) && (summary.elevatorMotor_TractionSheave.sheaveProfile!='belt'))"> | {{$t('3010')}}: {{ValueDecimalSeparator(summary.suspensionMeans.ropeDiameter2)}}  ({{ValueDecimalSeparator(summary.suspensionMeans.ropeDiameter)}}) mm</span>
                                                    <span v-if="((summary.suspensionMeans.ropeDiameter == summary.suspensionMeans.ropeDiameter2) && (summary.elevatorMotor_TractionSheave.sheaveProfile!='belt'))"> | {{$t('3010')}}: {{ValueDecimalSeparator(summary.suspensionMeans.ropeDiameter)}} mm</span>
                                                    <span> | </span>
                                                    <span v-if="((summary.elevatorMotor_TractionSheave.sheaveProfile=='circular undercut groove')&&(summary.elevatorMotor_TractionSheave.sheaveHardened==false))">{{$t('1131')}}: {{summary.elevatorMotor_TractionSheave.undercutAngle}} °</span>
                                                    <span v-if="((summary.elevatorMotor_TractionSheave.sheaveProfile=='V-groove with undercut')&&(summary.elevatorMotor_TractionSheave.sheaveHardened==false))">{{$t('1133')}}: {{summary.elevatorMotor_TractionSheave.vgrooveAngle}} °</span>
                                                    <span v-if="((summary.elevatorMotor_TractionSheave.sheaveProfile=='belt')&&(summary.elevatorMotor_TractionSheave.sheaveHardened==false))">{{$t('1360')}}: {{ValueDecimalSeparator(summary.suspensionMeans.cordDiameter)}} x {{ValueDecimalSeparator(summary.suspensionMeans.beltWidth)}}</span>
                                                    <span v-if="((summary.elevatorMotor_TractionSheave.sheaveProfile=='circular groove')&&(summary.elevatorMotor_TractionSheave.sheaveHardened==false))">{{$t('1424')}}</span>
                                                    <span v-if="((summary.elevatorMotor_TractionSheave.sheaveProfile=='V-groove')&&(summary.elevatorMotor_TractionSheave.sheaveHardened==true))">{{$t('1423')}} {{$t('1319')}}: {{summary.elevatorMotor_TractionSheave.vgrooveAngle}} °</span>
                                                    <span v-if="((summary.elevatorMotor_TractionSheave.sheaveProfile=='circular undercut groove')&&(summary.elevatorMotor_TractionSheave.sheaveHardened==true))">{{$t('1131')}} {{$t('1319')}}: {{summary.elevatorMotor_TractionSheave.undercutAngle}} °</span>
                                                    <span v-if="((summary.elevatorMotor_TractionSheave.sheaveProfile=='V-groove with undercut')&&(summary.elevatorMotor_TractionSheave.sheaveHardened==true))">{{$t('1441')}}: {{summary.elevatorMotor_TractionSheave.vgrooveAngle}}° / {{summary.elevatorMotor_TractionSheave.undercutAngle}} °</span>
                                                </div>
                                            </div>
                                        </div>
                                        <!--<div style="max-width:275px; text-align:right">-->
                                        <!--<a class="ZAfont_family" href="#">{{$t('3196')}}</a>-->
                                        <!--</div>-->
                                    </div>
                                </div>
                                <div v-if="summary.elevatorMotor_TemperatureMonitoring != null">
                                    <hr />
                                    <div style="display:grid; grid-template-columns:auto auto;grid-gap:5px;">
                                        <div style="display:grid; grid-template-columns:15px auto; grid-gap:5px; align-items:center">
                                            <div>
                                                <i class="fas fa-plus my_color_00338e"></i>
                                            </div>
                                            <div>
                                                <span class="my_color_00338e ZAfont_family" style="font-weight:600; font-size:18px"> {{$t('3067')}}:</span>
                                                <span class="ZAfont_family" style="font-weight:600; color:#000000; font-size:20px"> {{summary.elevatorMotor_TemperatureMonitoring.typeDesignation}}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div v-if="summary.elevatorMotor_ForcedVentilation != null">
                                    <hr />
                                    <div style="display:grid; grid-template-columns:auto auto;grid-gap:5px;">
                                        <div style="display:grid; grid-template-columns:15px auto; grid-gap:5px; align-items:center">
                                            <div>
                                                <i class="fas fa-plus my_color_00338e"></i>
                                            </div>
                                            <div>
                                                <span class="my_color_00338e ZAfont_family" style="font-weight:600; font-size:18px"> {{$t('298')}}:</span>
                                                <span v-if="((summary.elevatorMotor_ForcedVentilation.necessary==true)||((summary.elevatorMotor_ForcedVentilation.necessary==false)&&(summary.elevatorMotor_ForcedVentilation.selected == true)))" class="ZAfont_family" style="font-weight:600; color:#000000; font-size:20px"> {{$t('1035')}}</span>
                                                <span v-if="((summary.elevatorMotor_ForcedVentilation.necessary==false)&&(summary.elevatorMotor_ForcedVentilation.selected == false))" class="ZAfont_family" style="font-weight:600; color:#000000; font-size:20px"> {{$t('1036')}}</span>
                                            </div>
                                            <!--<div style="max-width:275px; text-align:right">-->
                                            <!--<a class="ZAfont_family" href="#">{{$t('3196')}}</a>-->
                                            <!--</div>-->
                                        </div>
                                    </div>
                                </div>
                                <div v-if="summary.elevatorMotor_Brake != null">
                                    <hr />
                                    <div style="display:grid; grid-template-columns:auto auto;grid-gap:5px;">
                                        <div style="display:grid; grid-template-columns:15px auto; grid-gap:5px; align-items:center">
                                            <div>
                                                <i class="fas fa-plus my_color_00338e"></i>
                                            </div>
                                            <div>
                                                <span class="my_color_00338e ZAfont_family" style="font-weight:600; font-size:18px"> {{$t('3260')}}:</span>
                                                <span class="ZAfont_family" style="font-weight:600; color:#000000; font-size:20px"> {{summary.elevatorMotor_Brake.typeDesignation}}</span>
                                            </div>
                                            <div class="ZAfont_family ZAfont_size" style="grid-column: 2 / span 1">
                                                <span>{{$t('1313')}}: {{ValueDecimalSeparator(summary.elevatorMotor_Brake.brakingDistance)}} m</span>
                                                <span> | </span>
                                                <span>{{$t('3073')}}: {{summary.elevatorMotor_Brake.nominalVoltage}} V</span>
                                                <span> | </span>
                                                <span v-if="(summary.elevatorMotor_Brake.mechanicalRelease =='without')">{{$t('3045')}}: {{$t('1425')}}</span>
                                                <span v-if="(summary.elevatorMotor_Brake.mechanicalRelease =='hand release')">{{$t('3045')}}: {{$t('1426')}}</span>
                                                <span v-if="(summary.elevatorMotor_Brake.mechanicalRelease =='bowden cable hand release')">{{$t('3045')}}: {{$t('1427')}}</span>
                                                <span v-if="(summary.elevatorMotor_Brake.mechanicalRelease =='without bowden cable hand release')">{{$t('3045')}}: {{$t('1428')}}</span>
                                                <span v-if="(summary.elevatorMotor_Brake.mechanicalRelease =='prepare for bowden cable hand release')">{{$t('3045')}}: {{$t('1453')}}</span>
                                                <span> | </span>
                                                <span v-if="(summary.elevatorMotor_Brake.releaseMonitoring =='microswitch')">{{$t('208')}}: {{$t('1429')}}</span>
                                                <span v-if="(summary.elevatorMotor_Brake.releaseMonitoring =='proximity switch')">{{$t('208')}}: {{$t('1430')}}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div v-if="summary.elevatorMotor_Encoder != null">
                                    <hr />
                                    <div style="display:grid; grid-template-columns:auto auto;grid-gap:5px;">
                                        <div style="display:grid; grid-template-columns:15px auto; grid-gap:5px; align-items:center">
                                            <div>
                                                <i class="fas fa-plus my_color_00338e"></i>
                                            </div>
                                            <div>
                                                <span class="my_color_00338e ZAfont_family" style="font-weight:600; font-size:18px"> {{$t('3075')}}:</span>
                                                <span class="ZAfont_family" style="font-weight:600; color:#000000; font-size:20px"> {{summary.elevatorMotor_Encoder.typeDesignation}}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr />
                </div>
                <div v-if="summary.inverter != null">
                    <div class="ZAfont_family my_color_00338e" style="margin-top:60px;font-weight:600; font-size:1.5rem">{{$t('3025')}}</div>
                    <hr style="margin-top:0" />
                    <div>
                        <div style="display: grid; grid-template-columns: 150px auto; grid-column-gap: 5px;">
                            <div>
                                <Images :imageFilePath="summary.inverter_ImagePath" :maxImageHeight="80" :maxImageWidth="120"></Images>
                            </div>
                            <div>
                                <div style="display: grid; grid-template-columns: auto auto; grid-column-gap: 5px;">
                                    <div>
                                        <div v-if="summary.inverter.typeDesignation === 'other inverter'">
                                            <span class="my_color_00338e ZAfont_family" style="font-weight:600; font-size:18px">{{$t('3197')}}: </span>
                                            <span class="ZAfont_family" style="font-weight:600; color:#000000; font-size:20px"> {{$t('3261')}}</span>
                                        </div>
                                        <template v-else>
                                            <div>
                                                <span class="my_color_00338e ZAfont_family" style="font-weight:600; font-size:18px">{{$t('3197')}}: </span>
                                                <span class="ZAfont_family" style="font-weight:600; color:#000000; font-size:20px"> {{summary.inverter.typeDesignation}}</span>
                                            </div>
                                        </template>
                                        <div class="ZAfont_family ZAfont_size">
                                            <span>{{$t('1381')}}: {{ValueDecimalSeparator(summary.inverter.nominalCurrent)}} A</span>
                                            <span> | </span>
                                            <span>{{$t('3183')}}: {{ValueDecimalSeparator(summary.inverter.maximumOperatingCurrent)}} A</span>
                                            <span> | </span>
                                            <span>{{$t('3184')}}: {{ValueDecimalSeparator(summary.inverter.acceleration)}} m/s²</span>
                                        </div>
                                    </div>
                                    <!--<div style="max-width:275px; text-align:right">-->
                                    <!--<div class="ZAfont_family" style="font-weight:600; color:#000000; font-size:24px">XXX,XX €</div>-->
                                    <!--<a class="ZAfont_family" href="#">{{$t('3196')}}</a>-->
                                    <!--</div>-->
                                </div>
                            </div>
                        </div>
                        <hr />
                    </div>
                    <div v-if="summary.powerRecuperationUnit != null">
                        <div style="display: grid; grid-template-columns: 150px auto; grid-column-gap: 5px;">
                            <div>
                                <Images :imageFilePath="summary.powerRecuperationUnit_ImagePath" :maxImageHeight="80" :maxImageWidth="120"></Images>
                            </div>
                            <div>
                                <div style="display: grid; grid-template-columns: auto auto; grid-column-gap: 5px;">
                                    <div>
                                        <div>
                                            <div>
                                                <span class="my_color_00338e ZAfont_family" style="font-weight:600; font-size:18px">{{$t('3163')}}: </span>
                                                <span class="ZAfont_family" style="font-weight:600; color:#000000; font-size:20px"> {{summary.powerRecuperationUnit.typeDesignation}}</span>
                                            </div>
                                        </div>
                                        <div class="ZAfont_family ZAfont_size">
                                            <span>{{$t('3113')}}: {{ValueDecimalSeparator(summary.powerRecuperationUnit.maximumRecuperationPower)}} W</span>
                                            <span> | </span>
                                            <span>{{$t('3114')}}: {{ValueDecimalSeparator(summary.powerRecuperationUnit.maximumRecuperationCurrent)}} A</span>
                                        </div>
                                    </div>
                                    <!--<div style="max-width:275px; text-align:right">-->
                                    <!--<div class="ZAfont_family" style="font-weight:600; color:#000000; font-size:24px">XXX,XX €</div>-->
                                    <!--<a class="ZAfont_family" href="#">{{$t('3196')}}</a>-->
                                    <!--</div>-->
                                </div>
                            </div>
                        </div>
                        <hr />
                    </div>
                    <div v-if="summary.brakeResistor != null">
                        <div style="display: grid; grid-template-columns: 150px auto; grid-column-gap: 5px;">
                            <div>
                                <Images :imageFilePath="summary.brakeResistor_ImagePath" :maxImageHeight="80" :maxImageWidth="120"></Images>
                            </div>
                            <div>
                                <div>
                                    <div style="display: grid; grid-template-columns: auto auto; grid-column-gap: 5px;">
                                        <div>
                                            <div>
                                                <span class="my_color_00338e ZAfont_family" style="font-weight:600; font-size:18px">{{$t('388')}}: </span>
                                                <span class="ZAfont_family" style="font-weight:600; color:#000000; font-size:20px"> {{summary.brakeResistor.typeDesignation}}</span>
                                            </div>
                                        </div>
                                    </div>

                                    <!--<div style="max-width:275px; text-align:right">-->
                                    <!--<div class="ZAfont_family" style="font-weight:600; color:#000000; font-size:24px">XXX,XX €</div>-->
                                    <!--<a class="ZAfont_family" href="#">{{$t('3196')}}</a>-->
                                    <!--</div>-->
                                </div>
                            </div>
                        </div>
                        <hr />
                    </div>
                    <div v-if="summary.brakeControl != null">
                        <div style="display: grid; grid-template-columns: 150px auto; grid-column-gap: 5px;">
                            <div>
                                <Images :imageFilePath="summary.brakeControl_ImagePath" :maxImageHeight="80" :maxImageWidth="120"></Images>
                            </div>
                            <div>
                                <div style="display: grid; grid-template-columns: auto auto; grid-column-gap: 5px;">
                                    <div>
                                        <div>
                                            <div>
                                                <span class="my_color_00338e ZAfont_family" style="font-weight:600; font-size:18px">{{$t('3198')}}: </span>
                                                <span class="ZAfont_family" style="font-weight:600; color:#000000; font-size:20px"> {{summary.brakeControl.typeDesignation}}</span>
                                            </div>
                                        </div>
                                        <div class="ZAfont_family ZAfont_size">
                                            <span>{{$t('3116')}}: {{ValueDecimalSeparator(summary.brakeControl.operatingVoltageSafetyCircuit)}} V</span>
                                            <span> | </span>
                                            <span>{{$t('3199')}}: {{ValueDecimalSeparator(summary.brakeControl.ratedPowerAtOutputVoltage)}} W</span>
                                        </div>
                                    </div>
                                    <!--<div style="max-width:275px; text-align:right">-->
                                    <!--<div class="ZAfont_family" style="font-weight:600; color:#000000; font-size:24px">XXX,XX €</div>-->
                                    <!--<a class="ZAfont_family" href="#">{{$t('3196')}}</a>-->
                                    <!--</div>-->
                                </div>
                            </div>
                        </div>
                        <hr />
                    </div>
                </div>
                <div v-if="summary.frames != null">
                    <div class="ZAfont_family my_color_00338e" style="margin-top:60px;font-weight:600; font-size:1.5rem">{{$t('3255')}} {{$t('179')}} {{$t('1016')}} </div>
                    <hr style="margin-top:0" />
                    <div>
                        <div style="display: grid; grid-template-columns: 150px auto; grid-column-gap: 5px;">
                            <div>
                                <Images :imageFilePath="summary.frame_ImagePath" :maxImageHeight="80" :maxImageWidth="120"></Images>
                            </div>
                            <div>
                                <div style="display: grid; grid-template-columns: auto auto; grid-column-gap: 5px;">
                                    <div>
                                        <span class="my_color_00338e ZAfont_family" style="font-weight:600; font-size:18px">{{$t('3255')}}: </span>
                                        <span class="ZAfont_family" style="font-weight:600; color:#000000; font-size:20px"> {{summary.frames.type}}</span>

                                        <div class="ZAfont_family ZAfont_size">
                                            <span v-if="summary.frame_TypeDesignation==null || summary.frame_TypeDesignation.useTextKey==false || summary.frame_TypeDesignation.textKey==null">{{$t('407')}}: {{summary.frames.typeDesignation}}</span>
                                            <span v-if="summary.frame_TypeDesignation!=null && summary.frame_TypeDesignation.useTextKey==true && summary.frame_TypeDesignation.textKey!=null">{{$t('407')}}: {{$t(summary.frame_TypeDesignation.textKey)}}</span>
                                            <span> | </span>
                                            <span>{{$t('407')}}: {{summary.frames.type}}</span>
                                            <span> | </span>
                                            <span>{{$t('408')}}: {{summary.frames.axleLoad}} kg</span>
                                            <span> | </span>
                                            <span>{{$t('1026')}}: {{summary.suspensionMeans.verticalDistanceOfTheAxlesFromTractionSheaveAndDeflectionPulley}}</span>
                                            <span> | </span>
                                            <span>{{$t('1027')}}: {{summary.suspensionMeans.horizontalDistanceOfTheRopeHoles}}</span>
                                            <span> | </span>
                                            <span>{{$t('1193')}}: {{summary.frames.dimensionSheet}}</span>
                                            <span v-if="summary.frame_PositionPulley!=null && (summary.frame_PositionPulley.useTextKey==false || summary.frame_PositionPulley.textKey==null) && summary.frame_PositionPulley.text!=null"> | {{$t('3383')}}: {{summary.frames.frame_PositionPulley}}</span>
                                            <span v-if="summary.frame_PositionPulley!=null && summary.frame_PositionPulley.useTextKey==true && summary.frame_PositionPulley.textKey!=null"> | {{$t('3383')}}: {{$t(summary.frame_PositionPulley.textKey)}}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <hr />
                    </div>
                    <div v-if="summary.pulleys != null">
                        <div style="display: grid; grid-template-columns: 150px auto; grid-column-gap: 5px;">
                            <div>
                                <Images :imageFilePath="summary.pulley_ImagePath" :maxImageHeight="80" :maxImageWidth="120"></Images>
                            </div>
                            <div>
                                <div style="display: grid; grid-template-columns: auto auto; grid-column-gap: 5px;">
                                    <div>
                                        <div>
                                            <div>
                                                <span v-if="summary.pulleys.numberOfPulleys>1" class="my_color_00338e ZAfont_family" style="font-weight:600; font-size:18px">2x </span>
                                                <span class="my_color_00338e ZAfont_family" style="font-weight:600; font-size:18px">{{$t('1062')}}: </span>
                                                <span v-if="summary.pulleys.type!='on request' && (summary.pulley_Type==null || summary.pulley_Type.useTextKey==false || summary.pulley_Type.textKey==null)" class="ZAfont_family" style="font-weight:600; color:#000000; font-size:20px"> {{summary.pulleys.type}}</span>
                                                <span v-if="summary.pulleys.type!='on request' && (summary.pulley_Type!=null && summary.pulley_Type.useTextKey==true && summary.pulley_Type.textKey!=null)" class="ZAfont_family" style="font-weight:600; color:#000000; font-size:20px"> {{$t(summary.pulley_Type.textKey)}}</span>
                                                <span v-if="summary.pulleys.type=='on request'" class="ZAfont_family" style="font-weight:600; color:#000000; font-size:20px"> {{$t('3378')}}</span>
                                            </div>
                                        </div>
                                        <div class="ZAfont_family ZAfont_size">
                                            <span>{{$t('3038')}}: {{summary.pulleys.diameter}} mm</span>
                                            <span v-if="summary.pulleys.type!='on request'">
                                                <span> | </span>
                                                <span>{{$t('1182')}}: {{summary.pulleys.sheaveWidth}} mm</span>
                                            </span>
                                            <span v-if="summary.pulleys.type!='on request'">
                                                <span> | </span>
                                                <span>{{$t('165')}}: {{summary.pulleys.numberOfGrooves}}</span>
                                                <span> x {{ValueDecimalSeparator(summary.pulleys.grooveDiameterMinimum)}}</span>
                                                <span v-if="summary.pulleys.grooveDiameterMinimum==summary.pulleys.grooveDiameterMaximum"> mm</span>
                                                <span v-if="summary.pulleys.grooveDiameterMinimum!=summary.pulleys.grooveDiameterMaximum"> / {{ValueDecimalSeparator(summary.pulleys.grooveDiameterMaximum)}} mm</span>
                                            </span>
                                            <span>
                                                <span> | </span>
                                                <span>{{$t('1294')}}: {{summary.pulleys.grooveDistance}} mm</span>
                                            </span>
                                            <span v-if="summary.pulleys.type!='on request'">
                                                <span> | </span>
                                                <span>{{$t('106')}}: {{summary.pulleys.maximumLoad}}</span>
                                            </span>
                                            <span v-if="summary.pulleys.type!='on request'">
                                                <span> | </span>
                                                <span v-if="summary.pulley_Type==null || summary.pulley_Type.useTextKey==false || summary.pulley_Type.textKey==null">{{$t('407')}}: {{summary.pulleys.type}}</span>
                                                <span v-if="summary.pulley_Type!=null && summary.pulley_Type.useTextKey==true  && summary.pulley_Type.textKey!=null">{{$t('407')}}: {{$t(summary.pulley_Type.textKey)}}</span>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <hr />
                    </div>
                    <div v-if="summary.frameDeliverOptions != null && showFrameOptions == true">
                        <div style="display: grid; grid-template-columns: 150px auto; grid-column-gap: 5px;">
                            <div>
                                <Images :imageFilePath="summary.frameOptions_ImagePath" :maxImageHeight="80" :maxImageWidth="120"></Images>
                            </div>
                            <div>
                                <div>
                                    <div style="display: grid; grid-template-columns: auto auto; grid-column-gap: 5px;">
                                        <div>
                                            <div>
                                                <span class="my_color_00338e ZAfont_family" style="font-weight:600; font-size:18px">{{$t('1016')}}: </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="ZAfont_family ZAfont_size">
                                        <!--<span v-if="summary.frameDeliverOptions.motorConsole=='frame'">{{$t('3396')}}: {{$t('3381')}}</span>
                                <span v-if="summary.frameDeliverOptions.motorConsole=='motor'">{{$t('3396')}}: {{$t('3382')}}</span>-->
                                        <!--<span v-if="summary.frameDeliverOptions.selectedOperatingInstructions!=null && summary.frameDeliverOptions.selectedOperatingInstructions==true">-->
                                        <!--<span> | </span>-->
                                        <!--<span>{{$t('3390')}}<span v-if="summary.frameOperatingInstructionKey>0"> {{$t(summary.frameOperatingInstructionKey)}}</span></span>
                                </span>-->
                                        <span v-if="summary.frameDeliverOptions.selectedAssembled!=null && summary.frameDeliverOptions.selectedAssembled==true">
                                            <!--<span v-if="(summary.frameDeliverOptions.selectedOperatingInstructions!=null && summary.frameDeliverOptions.selectedOperatingInstructions==true)"> | </span>-->
                                            <span>{{$t('3384')}}</span>
                                        </span>
                                        <span v-if="summary.frameDeliverOptions.selectedEmergencyStopSwitch!=null && summary.frameDeliverOptions.selectedEmergencyStopSwitch==true">
                                            <span v-if="(summary.frameDeliverOptions.selectedAssembled!=null && summary.frameDeliverOptions.selectedAssembled==true)"> | </span>
                                            <span>{{$t('3387')}}</span>
                                        </span>
                                        <!--<span v-if="summary.frameDeliverOptions.selectedIsolation!=null && summary.frameDeliverOptions.selectedIsolation==true">
                                    <span> | </span>
                                    <span>{{$t('3389')}}</span>
                                </span>-->
                                        <span v-if="summary.frameDeliverOptions.selectedRetrofit!=null && summary.frameDeliverOptions.selectedRetrofit==true">
                                            <span v-if="(summary.frameDeliverOptions.selectedAssembled!=null && summary.frameDeliverOptions.selectedAssembled==true) ||
                                                  (summary.frameDeliverOptions.selectedEmergencyStopSwitch!=null && summary.frameDeliverOptions.selectedEmergencyStopSwitch==true)"> | </span>
                                            <span>{{$t('3386')}}</span>
                                        </span>
                                        <span v-if="summary.frameDeliverOptions.selectedRopeGuard!=null && summary.frameDeliverOptions.selectedRopeGuard==true">
                                            <span v-if="(summary.frameDeliverOptions.selectedAssembled!=null && summary.frameDeliverOptions.selectedAssembled==true) ||
                                                  (summary.frameDeliverOptions.selectedEmergencyStopSwitch!=null && summary.frameDeliverOptions.selectedEmergencyStopSwitch==true) ||
                                                  (summary.frameDeliverOptions.selectedRetrofit!=null && summary.frameDeliverOptions.selectedRetrofit==true)"> | </span>
                                            <span>{{$t('3388')}}</span>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <hr />
                    </div>
                </div>
            </div>
            <div style="margin-left:0px; margin-right:0px">
                <SummaryInformation :additional="false"></SummaryInformation>
            </div>
        </div>
        <!--<b-modal ok-only id="modal-accept_terms_of_request" title="Anfragebedingungen" height="1280px" width="720px" >
        <iframe src="/pdf/Nutzungsbedingungen-ZAmon-ZAlift-09-2018.pdf" height="1280" width="720"></iframe>-->
        <!--<p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.</p>
        <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.</p>
        <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.</p>
        <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.</p>
        <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.</p>
        <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.</p>
        <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.</p>
        <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.</p>-->
        <!--</b-modal>-->

        <div v-if="za_employee == true && information != null && information.data != null && information.data.price != null && ((Object.keys(this.summary).length > 0))" style="display: grid; grid-template-columns: auto 400px; grid-column-gap: 50px;">
            <div>
                <div v-if="summary.elevatorMotor_Machine != null">
                    <div class="ZAfont_family my_color_00338e" style="font-weight: 600; font-size: 1.5rem; display: grid; grid-template-columns: auto auto 50px; grid-column-gap: 5px;">
                        <div style="text-align:left; float:left">{{$t('3024')}} </div>
                        <div class="ZAfont_family ZAfont_size" style="margin-left: auto; margin-right: 0; width: 260px">
                            <div v-bind:class="{ navigation_relative:  scrollYPosition <= 150, navigation_fixed: scrollYPosition > 150}" style="display: grid; grid-template-columns: 1fr 1fr 1fr; grid-gap: 5px; text-align: left;"><span style="background-color: lightgrey; padding-left: 10px;">Brutto [€]</span><span style="background-color: lightgrey; padding-left: 10px">Rabatt [%]</span><span style="background-color: lightgrey; padding-left: 10px ">Netto [€]</span></div>
                            <!--<a class="ZAfont_family" style="font-size: 12px;" href="#">{{$t('3196')}}</a>-->
                        </div>
                        <div> </div>
                    </div>
                    <hr style="margin-top:0px" />
                    <div>
                        <div style="display: grid; grid-template-columns: 150px auto; grid-column-gap: 5px;">
                            <div>
                                <Images :imageFilePath="summary.elevatorMotor_Machine_ImagePath" :maxImageHeight="80" :maxImageWidth="120"></Images>
                            </div>
                            <div>
                                <div style="display: grid; grid-template-columns: auto auto 50px; grid-column-gap: 5px;">
                                    <div>
                                        <div>
                                            <div>
                                                <span class="my_color_00338e ZAfont_family" style="font-weight:600; font-size:18px">{{$t('3027')}}</span>
                                            </div>
                                            <!--<div style="max-width: 275px; text-align: right">
                                            <div class="ZAfont_family" style="font-weight:600; color:#000000; font-size:24px">XXX,XX €</div>
                                            <a class="ZAfont_family" href="#">{{$t('3196')}}</a>
                                            </div>-->
                                        </div>
                                        <div>
                                            <div style="display:grid; grid-template-columns:15px auto; grid-gap:5px; align-items:center">
                                                <div>
                                                    <span class="fas fa-plus my_color_00338e"></span>
                                                </div>
                                                <div class="ZAfont_family ZAfont_size">
                                                    <span class="my_color_00338e" style="font-weight: 600; font-size: 18px"> {{$t('3028')}}:</span>
                                                    <span style="font-weight:600; color:#000000; font-size:20px"> {{summary.elevatorMotor_Machine.typeDesignation}}</span>
                                                </div>
                                                <!--<div v-if="za_employee" style="display: grid; grid-template-columns: auto 250px">
                                                    <div class="ZAfont_family ZAfont_size" style="text-align: left; float: left">
                                                        <span class="my_color_00338e" style="font-weight: 600; font-size: 18px"> {{$t('3028')}}:</span>
                                                        <span style="font-weight:600; color:#000000; font-size:20px"> {{summary.elevatorMotor_Machine.typeDesignation}}</span>
                                                    </div>
                                                </div>-->
                                            <div class="ZAfont_family ZAfont_size" style="grid-column: 2 / span 1">
                                                <div>{{$t('410')}}: {{summary.elevatorMotor_Machine.gearRatio1}}:{{summary.elevatorMotor_Machine.gearRatio2}}</div>
                                                <div>{{$t('3048')}}: {{summary.elevatorMotor_Machine.maximumAxleLoad}} kg</div>
                                                <div>{{$t('1091')}}: {{summary.elevatorMotor_Machine.nominalRotationSpeed}} rpm</div>
                                                <div>{{$t('3049')}}: {{ValueDecimalSeparator(summary.elevatorMotor_Machine.operatingCurrent)}} A</div>
                                                <div>{{$t('3051')}}: {{ValueDecimalSeparator(summary.elevatorMotor_Machine.operatingTorque)}} Nm</div>
                                                <div>{{$t('1372')}}: {{ValueDecimalSeparator(summary.elevatorSystems.dutyCycle)}} %</div>
                                                <div>{{$t('1092')}}: {{ValueDecimalSeparator(summary.elevatorSystems.startsPerHour)}}</div>
                                                <div>{{$t('3050')}}: {{ValueDecimalSeparator(summary.elevatorMotor_Machine.electricalUtilization)}} %</div>
                                                <div>{{$t('3052')}}: {{ValueDecimalSeparator(summary.elevatorMotor_Machine.mechanicalUtilization)}} %</div>
                                                <div v-if="information.data.price!=null&&information.data.price.cableInfoValue!=null">{{$t('3551')}}: {{information.data.price.cableInfoValue}} m</div>
                                            </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div v-if="information.data.price.motor.display==true && information.data.price.motor.valid==true" class="ZAfont_family ZAfont_size my_color_00338e" style="margin-left: auto; margin-right: 0; width: 260px; display: grid; grid-template-columns: 1fr 1fr 1fr; grid-gap: 5px; text-align: right; font-weight: 600;">
                                        <span class="my_summaryInfo"><span v-if="information.data.price.motor.grossPrice!=null">{{isDataANumber(information.data.price.motor.grossPrice,'€')}}</span></span>
                                        <span class="my_summaryInfo"><span v-if="information.data.price.motor.discount !=null">{{isDataANumber(information.data.price.motor.discount,'%')}}</span></span>
                                        <span class="my_summaryInfo"><span v-if="information.data.price.motor.netPrice!=null">{{isDataANumber(information.data.price.motor.netPrice,'€')}}</span></span>
                                    </div>
                                    <div v-if="information.data.price.motor.valid==false" class="ZAfont_family ZAfont_size my_color_00338e" style="margin-left: auto; margin-right: 0; width: 260px; display: grid; grid-template-columns: 1fr 1fr 1fr; grid-gap: 5px; text-align: right; font-weight: 600;">
                                        <span class="my_summaryInfo"><span> </span></span>
                                        <span class="my_summaryInfo"><span> </span></span>
                                        <span class="my_summaryInfo">Auf Anfrage</span>
                                    </div>
                                    <div class="ZAfont_family ZAfont_size">
                                        <span v-if="showStoredInformation==true && storedInformation.price.motor.display==true && storedInformation.price.motor.netPrice!=null">
                                            <span v-if="storedInformation.price.motor.valid==true && storedInformation.price.motor.netPrice!=null"> ({{isDataANumber(storedInformation.price.motor.netPrice,'€')}})</span>
                                            <span v-if="storedInformation.price.motor.valid==false"> (Auf Anfrage)</span>
                                        </span>
                                    </div>
                                </div>
                                <div>
                                    <hr />
                                    <!--<div style="display:grid; grid-template-columns:auto auto;grid-gap:5px">-->
                                    <div>
                                        <div style="display:grid; grid-template-columns:15px auto; grid-gap:5px; align-items:center">
                                            <div>
                                                <i class="fas fa-plus my_color_00338e"></i>
                                            </div>
                                            <div>
                                                <span class="my_color_00338e ZAfont_family" style="font-weight: 600; font-size: 18px"> {{$t('1301')}}:</span>
                                                <span v-if="((summary.suspensionMeans.ropeDiameter != summary.suspensionMeans.ropeDiameter2) && (summary.elevatorMotor_TractionSheave.sheaveProfile=='circular undercut groove')&&(summary.elevatorMotor_TractionSheave.sheaveHardened==false))" class="ZAfont_family" style="font-weight:600; color:#000000; font-size:20px"> {{summary.elevatorMotor_TractionSheave.diameter}}/{{summary.elevatorMotor_TractionSheave.sheaveWidth}}/{{summary.elevatorMotor_TractionSheave.grooveDistance}}/{{summary.elevatorMotor_TractionSheave.numberOfGrooves}}x{{ValueDecimalSeparator(summary.suspensionMeans.ropeDiameter2)}} ({{ValueDecimalSeparator(summary.suspensionMeans.ropeDiameter)}})/U{{summary.elevatorMotor_TractionSheave.undercutAngle}}</span>
                                                <span v-if="((summary.suspensionMeans.ropeDiameter != summary.suspensionMeans.ropeDiameter2) && (summary.elevatorMotor_TractionSheave.sheaveProfile=='V-groove with undercut')&&(summary.elevatorMotor_TractionSheave.sheaveHardened==false))" class="ZAfont_family" style="font-weight:600; color:#000000; font-size:20px"> {{summary.elevatorMotor_TractionSheave.diameter}}/{{summary.elevatorMotor_TractionSheave.sheaveWidth}}/{{summary.elevatorMotor_TractionSheave.grooveDistance}}/{{summary.elevatorMotor_TractionSheave.numberOfGrooves}}x{{ValueDecimalSeparator(summary.suspensionMeans.ropeDiameter2)}} ({{ValueDecimalSeparator(summary.suspensionMeans.ropeDiameter)}})/K{{summary.elevatorMotor_TractionSheave.vgrooveAngle}}</span>
                                                <span v-if="((summary.elevatorMotor_TractionSheave.sheaveProfile=='belt')&&(summary.elevatorMotor_TractionSheave.sheaveHardened==false))" class="ZAfont_family" style="font-weight:600; color:#000000; font-size:20px"> {{summary.elevatorMotor_TractionSheave.diameter}}/{{summary.elevatorMotor_TractionSheave.sheaveWidth}}/{{summary.elevatorMotor_TractionSheave.grooveDistance}}/{{summary.elevatorMotor_TractionSheave.numberOfGrooves}}x{{ValueDecimalSeparator(summary.suspensionMeans.cordDiameter)}}x{{ValueDecimalSeparator(summary.suspensionMeans.beltWidth)}}/R</span>
                                                <span v-if="((summary.suspensionMeans.ropeDiameter != summary.suspensionMeans.ropeDiameter2) && (summary.elevatorMotor_TractionSheave.sheaveProfile=='circular groove')&&(summary.elevatorMotor_TractionSheave.sheaveHardened==false))" class="ZAfont_family" style="font-weight:600; color:#000000; font-size:20px"> {{summary.elevatorMotor_TractionSheave.diameter}}/{{summary.elevatorMotor_TractionSheave.sheaveWidth}}/{{summary.elevatorMotor_TractionSheave.grooveDistance}}/{{summary.elevatorMotor_TractionSheave.numberOfGrooves}}x{{ValueDecimalSeparator(summary.suspensionMeans.ropeDiameter2)}} ({{ValueDecimalSeparator(summary.suspensionMeans.ropeDiameter)}})/R</span>
                                                <span v-if="((summary.suspensionMeans.ropeDiameter != summary.suspensionMeans.ropeDiameter2) && (summary.elevatorMotor_TractionSheave.sheaveProfile=='V-groove')&&(summary.elevatorMotor_TractionSheave.sheaveHardened==true))" class="ZAfont_family" style="font-weight:600; color:#000000; font-size:20px"> {{summary.elevatorMotor_TractionSheave.diameter}}/{{summary.elevatorMotor_TractionSheave.sheaveWidth}}/{{summary.elevatorMotor_TractionSheave.grooveDistance}}/{{summary.elevatorMotor_TractionSheave.numberOfGrooves}}x{{ValueDecimalSeparator(summary.suspensionMeans.ropeDiameter2)}} ({{ValueDecimalSeparator(summary.suspensionMeans.ropeDiameter)}})/HK{{summary.elevatorMotor_TractionSheave.vgrooveAngle}}</span>
                                                <span v-if="((summary.suspensionMeans.ropeDiameter != summary.suspensionMeans.ropeDiameter2) && (summary.elevatorMotor_TractionSheave.sheaveProfile=='circular undercut groove')&&(summary.elevatorMotor_TractionSheave.sheaveHardened==true))" class="ZAfont_family" style="font-weight:600; color:#000000; font-size:20px"> {{summary.elevatorMotor_TractionSheave.diameter}}/{{summary.elevatorMotor_TractionSheave.sheaveWidth}}/{{summary.elevatorMotor_TractionSheave.grooveDistance}}/{{summary.elevatorMotor_TractionSheave.numberOfGrooves}}x{{ValueDecimalSeparator(summary.suspensionMeans.ropeDiameter2)}} ({{ValueDecimalSeparator(summary.suspensionMeans.ropeDiameter)}})/HU{{summary.elevatorMotor_TractionSheave.undercutAngle}}</span>
                                                <span v-if="((summary.suspensionMeans.ropeDiameter != summary.suspensionMeans.ropeDiameter2) && (summary.elevatorMotor_TractionSheave.sheaveProfile=='V-groove with undercut')&&(summary.elevatorMotor_TractionSheave.sheaveHardened==true))" class="ZAfont_family" style="font-weight:600; color:#000000; font-size:20px"> {{summary.elevatorMotor_TractionSheave.diameter}}/{{summary.elevatorMotor_TractionSheave.sheaveWidth}}/{{summary.elevatorMotor_TractionSheave.grooveDistance}}/{{summary.elevatorMotor_TractionSheave.numberOfGrooves}}x{{ValueDecimalSeparator(summary.suspensionMeans.ropeDiameter2)}} ({{ValueDecimalSeparator(summary.suspensionMeans.ropeDiameter)}})/K{{summary.elevatorMotor_TractionSheave.vgrooveAngle}}U{{summary.elevatorMotor_TractionSheave.undercutAngle}}</span>

                                                <span v-if="((summary.suspensionMeans.ropeDiameter == summary.suspensionMeans.ropeDiameter2) && (summary.elevatorMotor_TractionSheave.sheaveProfile=='circular undercut groove')&&(summary.elevatorMotor_TractionSheave.sheaveHardened==false))" class="ZAfont_family" style="font-weight:600; color:#000000; font-size:20px"> {{summary.elevatorMotor_TractionSheave.diameter}}/{{summary.elevatorMotor_TractionSheave.sheaveWidth}}/{{summary.elevatorMotor_TractionSheave.grooveDistance}}/{{summary.elevatorMotor_TractionSheave.numberOfGrooves}}x{{ValueDecimalSeparator(summary.suspensionMeans.ropeDiameter)}}/U{{summary.elevatorMotor_TractionSheave.undercutAngle}}</span>
                                                <span v-if="((summary.suspensionMeans.ropeDiameter == summary.suspensionMeans.ropeDiameter2) && (summary.elevatorMotor_TractionSheave.sheaveProfile=='V-groove with undercut')&&(summary.elevatorMotor_TractionSheave.sheaveHardened==false))" class="ZAfont_family" style="font-weight:600; color:#000000; font-size:20px"> {{summary.elevatorMotor_TractionSheave.diameter}}/{{summary.elevatorMotor_TractionSheave.sheaveWidth}}/{{summary.elevatorMotor_TractionSheave.grooveDistance}}/{{summary.elevatorMotor_TractionSheave.numberOfGrooves}}x{{ValueDecimalSeparator(summary.suspensionMeans.ropeDiameter)}}/K{{summary.elevatorMotor_TractionSheave.vgrooveAngle}}</span>
                                                <span v-if="((summary.suspensionMeans.ropeDiameter == summary.suspensionMeans.ropeDiameter2) && (summary.elevatorMotor_TractionSheave.sheaveProfile=='circular groove')&&(summary.elevatorMotor_TractionSheave.sheaveHardened==false))" class="ZAfont_family" style="font-weight:600; color:#000000; font-size:20px"> {{summary.elevatorMotor_TractionSheave.diameter}}/{{summary.elevatorMotor_TractionSheave.sheaveWidth}}/{{summary.elevatorMotor_TractionSheave.grooveDistance}}/{{summary.elevatorMotor_TractionSheave.numberOfGrooves}}x{{ValueDecimalSeparator(summary.suspensionMeans.ropeDiameter)}}/R</span>
                                                <span v-if="((summary.suspensionMeans.ropeDiameter == summary.suspensionMeans.ropeDiameter2) && (summary.elevatorMotor_TractionSheave.sheaveProfile=='V-groove')&&(summary.elevatorMotor_TractionSheave.sheaveHardened==true))" class="ZAfont_family" style="font-weight:600; color:#000000; font-size:20px"> {{summary.elevatorMotor_TractionSheave.diameter}}/{{summary.elevatorMotor_TractionSheave.sheaveWidth}}/{{summary.elevatorMotor_TractionSheave.grooveDistance}}/{{summary.elevatorMotor_TractionSheave.numberOfGrooves}}x{{ValueDecimalSeparator(summary.suspensionMeans.ropeDiameter)}}/HK{{summary.elevatorMotor_TractionSheave.vgrooveAngle}}</span>
                                                <span v-if="((summary.suspensionMeans.ropeDiameter == summary.suspensionMeans.ropeDiameter2) && (summary.elevatorMotor_TractionSheave.sheaveProfile=='circular undercut groove')&&(summary.elevatorMotor_TractionSheave.sheaveHardened==true))" class="ZAfont_family" style="font-weight:600; color:#000000; font-size:20px"> {{summary.elevatorMotor_TractionSheave.diameter}}/{{summary.elevatorMotor_TractionSheave.sheaveWidth}}/{{summary.elevatorMotor_TractionSheave.grooveDistance}}/{{summary.elevatorMotor_TractionSheave.numberOfGrooves}}x{{ValueDecimalSeparator(summary.suspensionMeans.ropeDiameter)}}/HU{{summary.elevatorMotor_TractionSheave.undercutAngle}}</span>
                                                <span v-if="((summary.suspensionMeans.ropeDiameter == summary.suspensionMeans.ropeDiameter2) && (summary.elevatorMotor_TractionSheave.sheaveProfile=='V-groove with undercut')&&(summary.elevatorMotor_TractionSheave.sheaveHardened==true))" class="ZAfont_family" style="font-weight:600; color:#000000; font-size:20px"> {{summary.elevatorMotor_TractionSheave.diameter}}/{{summary.elevatorMotor_TractionSheave.sheaveWidth}}/{{summary.elevatorMotor_TractionSheave.grooveDistance}}/{{summary.elevatorMotor_TractionSheave.numberOfGrooves}}x{{ValueDecimalSeparator(summary.suspensionMeans.ropeDiameter)}}/K{{summary.elevatorMotor_TractionSheave.vgrooveAngle}}U{{summary.elevatorMotor_TractionSheave.undercutAngle}}</span>
                                            </div>
                                            <div class="ZAfont_family ZAfont_size" style="grid-column: 2 / span 1">
                                                <div style="display:grid; grid-template-columns:auto auto 50px;grid-gap:5px">
                                                    <div>
                                                        <div>{{$t('3038')}}: {{summary.elevatorMotor_TractionSheave.diameter}} mm</div>
                                                    </div>
                                                    <div v-if="information.data.price.motor_Option_TractionSheave.display==true && information.data.price.motor_Option_TractionSheave.valid==true" class="ZAfont_family ZAfont_size my_color_00338e" style="margin-left: auto; margin-right: 0; width: 260px; display: grid; grid-template-columns: 1fr 1fr 1fr; grid-gap: 5px; text-align: right; font-weight: 600;">
                                                        <span class="my_summaryInfo"><span v-if="information.data.price.motor_Option_TractionSheave.grossPrice!=null">{{isDataANumber(information.data.price.motor_Option_TractionSheave.grossPrice,'€')}}</span></span>
                                                        <span class="my_summaryInfo"><span v-if="information.data.price.motor_Option_TractionSheave.discount !=null">{{isDataANumber(information.data.price.motor_Option_TractionSheave.discount,'%')}}</span></span>
                                                        <span class="my_summaryInfo"><span v-if="information.data.price.motor_Option_TractionSheave.netPrice!=null">{{isDataANumber(information.data.price.motor_Option_TractionSheave.netPrice,'€')}}</span></span>
                                                    </div>
                                                    <div v-if="information.data.price.motor_Option_TractionSheave.valid==false" class="ZAfont_family ZAfont_size my_color_00338e" style="margin-left: auto; margin-right: 0; width: 260px; display: grid; grid-template-columns: 1fr 1fr 1fr; grid-gap: 5px; text-align: right; font-weight: 600;">
                                                        <span class="my_summaryInfo"><span> </span></span>
                                                        <span class="my_summaryInfo"><span> </span></span>
                                                        <span class="my_summaryInfo">Auf Anfrage</span>
                                                    </div>
                                                    <div class="ZAfont_family ZAfont_size">
                                                        <span v-if="showStoredInformation==true && storedInformation.price.motor_Option_TractionSheave.display==true">
                                                            <span v-if="storedInformation.price.motor_Option_TractionSheave.valid==true && storedInformation.price.motor_Option_TractionSheave.netPrice!=null"> ({{isDataANumber(storedInformation.price.motor_Option_TractionSheave.netPrice,'€')}})</span>
                                                            <span v-if="storedInformation.price.motor_Option_TractionSheave.valid==false"> (Auf Anfrage)</span>
                                                        </span>
                                                    </div>
                                                </div>
                                                <div style="display:grid; grid-template-columns:auto auto 50px;grid-gap:5px">
                                                    <div>
                                                        <div>{{$t('294')}}: {{summary.elevatorMotor_TractionSheave.grooveDistance}} mm</div>
                                                    </div>
                                                    <div v-if="information.data.price.motor_Option_GrooveDistance.display==true && information.data.price.motor_Option_GrooveDistance.valid==true" class="ZAfont_family ZAfont_size my_color_00338e" style="margin-left: auto; margin-right: 0; width: 260px; display: grid; grid-template-columns: 1fr 1fr 1fr; grid-gap: 5px; text-align: right; font-weight: 600;">
                                                        <span class="my_summaryInfo"><span v-if="information.data.price.motor_Option_GrooveDistance.grossPrice!=null">{{isDataANumber(information.data.price.motor_Option_GrooveDistance.grossPrice,'€')}}</span></span>
                                                        <span class="my_summaryInfo"><span v-if="information.data.price.motor_Option_GrooveDistance.discount !=null">{{isDataANumber(information.data.price.motor_Option_GrooveDistance.discount,'%')}}</span></span>
                                                        <span class="my_summaryInfo"><span v-if="information.data.price.motor_Option_GrooveDistance.netPrice!=null">{{isDataANumber(information.data.price.motor_Option_GrooveDistance.netPrice,'€')}}</span></span>
                                                    </div>
                                                    <div v-if="information.data.price.motor_Option_GrooveDistance.valid==false" class="ZAfont_family ZAfont_size my_color_00338e" style="margin-left: auto; margin-right: 0; width: 260px; display: grid; grid-template-columns: 1fr 1fr 1fr; grid-gap: 5px; text-align: right; font-weight: 600;">
                                                        <span class="my_summaryInfo"><span> </span></span>
                                                        <span class="my_summaryInfo"><span> </span></span>
                                                        <span class="my_summaryInfo">Auf Anfrage</span>
                                                    </div>
                                                    <div class="ZAfont_family ZAfont_size">
                                                        <span v-if="showStoredInformation==true && storedInformation.price.motor_Option_GrooveDistance.display==true && storedInformation.price.motor_Option_GrooveDistance.netPrice!=null">
                                                            <span v-if="storedInformation.price.motor_Option_GrooveDistance.valid==true && storedInformation.price.motor_Option_GrooveDistance.netPrice!=null"> ({{isDataANumber(storedInformation.price.motor_Option_GrooveDistance.netPrice,'€')}})</span>
                                                            <span v-if="storedInformation.price.motor_Option_GrooveDistance.valid==false"> (Auf Anfrage)</span>
                                                        </span>
                                                    </div>
                                                </div>
                                                <div>{{$t('1182')}}: {{summary.elevatorMotor_TractionSheave.sheaveWidth}} mm</div>
                                                <div>{{$t('165')}}: {{summary.elevatorMotor_TractionSheave.numberOfGrooves}}</div>
                                                <div v-if="((summary.suspensionMeans.ropeDiameter != summary.suspensionMeans.ropeDiameter2) && (summary.elevatorMotor_TractionSheave.sheaveProfile!='belt'))">{{$t('3010')}}: {{ValueDecimalSeparator(summary.suspensionMeans.ropeDiameter2)}}  ({{ValueDecimalSeparator(summary.suspensionMeans.ropeDiameter)}}) mm</div>
                                                <div v-if="((summary.suspensionMeans.ropeDiameter == summary.suspensionMeans.ropeDiameter2) && (summary.elevatorMotor_TractionSheave.sheaveProfile!='belt'))">{{$t('3010')}}: {{ValueDecimalSeparator(summary.suspensionMeans.ropeDiameter)}} mm</div>
                                                <div style="display:grid; grid-template-columns:auto auto 50px;grid-gap:5px">
                                                    <div>
                                                        <div v-if="((summary.elevatorMotor_TractionSheave.sheaveProfile=='circular undercut groove')&&(summary.elevatorMotor_TractionSheave.sheaveHardened==false))">{{$t('1131')}}: {{summary.elevatorMotor_TractionSheave.undercutAngle}} °</div>
                                                        <div v-if="((summary.elevatorMotor_TractionSheave.sheaveProfile=='V-groove with undercut')&&(summary.elevatorMotor_TractionSheave.sheaveHardened==false))">{{$t('1133')}}: {{summary.elevatorMotor_TractionSheave.vgrooveAngle}} °</div>
                                                        <div v-if="((summary.elevatorMotor_TractionSheave.sheaveProfile=='belt')&&(summary.elevatorMotor_TractionSheave.sheaveHardened==false))">{{$t('1360')}}: {{ValueDecimalSeparator(summary.suspensionMeans.cordDiameter)}} x {{ValueDecimalSeparator(summary.suspensionMeans.beltWidth)}}</div>
                                                        <div v-if="((summary.elevatorMotor_TractionSheave.sheaveProfile=='circular groove')&&(summary.elevatorMotor_TractionSheave.sheaveHardened==false))">{{$t('1424')}}</div>
                                                        <div v-if="((summary.elevatorMotor_TractionSheave.sheaveProfile=='V-groove')&&(summary.elevatorMotor_TractionSheave.sheaveHardened==true))">{{$t('1423')}} {{$t('1319')}}: {{summary.elevatorMotor_TractionSheave.vgrooveAngle}} °</div>
                                                        <div v-if="((summary.elevatorMotor_TractionSheave.sheaveProfile=='circular undercut groove')&&(summary.elevatorMotor_TractionSheave.sheaveHardened==true))">{{$t('1131')}} {{$t('1319')}}: {{summary.elevatorMotor_TractionSheave.undercutAngle}} °</div>
                                                        <div v-if="((summary.elevatorMotor_TractionSheave.sheaveProfile=='V-groove with undercut')&&(summary.elevatorMotor_TractionSheave.sheaveHardened==true))">{{$t('1441')}}: {{summary.elevatorMotor_TractionSheave.vgrooveAngle}}° / {{summary.elevatorMotor_TractionSheave.undercutAngle}} °</div>
                                                    </div>
                                                    <div v-if="information.data.price.motor_Option_Hardening.display==true && information.data.price.motor_Option_Hardening.valid==true" class="ZAfont_family ZAfont_size my_color_00338e" style="margin-left: auto; margin-right: 0; width: 260px; display: grid; grid-template-columns: 1fr 1fr 1fr; grid-gap: 5px; text-align: right; font-weight: 600;">
                                                        <span class="my_summaryInfo"><span v-if="information.data.price.motor_Option_Hardening.grossPrice!=null">{{isDataANumber(information.data.price.motor_Option_Hardening.grossPrice,'€')}}</span></span>
                                                        <span class="my_summaryInfo"><span v-if="information.data.price.motor_Option_Hardening.discount !=null">{{isDataANumber(information.data.price.motor_Option_Hardening.discount,'%')}}</span></span>
                                                        <span class="my_summaryInfo"><span v-if="information.data.price.motor_Option_Hardening.netPrice!=null">{{isDataANumber(information.data.price.motor_Option_Hardening.netPrice,'€')}}</span></span>
                                                    </div>
                                                    <div v-if="information.data.price.motor_Option_Hardening.valid==false" class="ZAfont_family ZAfont_size my_color_00338e" style="margin-left: auto; margin-right: 0; width: 260px; display: grid; grid-template-columns: 1fr 1fr 1fr; grid-gap: 5px; text-align: right; font-weight: 600;">
                                                        <span class="my_summaryInfo"><span> </span></span>
                                                        <span class="my_summaryInfo"><span> </span></span>
                                                        <span class="my_summaryInfo">Auf Anfrage</span>
                                                    </div>
                                                    <div class="ZAfont_family ZAfont_size">
                                                        <span v-if="showStoredInformation==true && storedInformation.price.motor_Option_Hardening.display==true && storedInformation.price.motor_Option_Hardening.netPrice!=null">
                                                            <span v-if="storedInformation.price.motor_Option_Hardening.valid==true && storedInformation.price.motor_Option_Hardening.netPrice!=null"> ({{isDataANumber(storedInformation.price.motor_Option_Hardening.netPrice,'€')}})</span>
                                                            <span v-if="storedInformation.price.motor_Option_Hardening.valid==false"> (Auf Anfrage)</span>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div v-if="summary.elevatorMotor_TemperatureMonitoring != null">
                                    <hr />
                                    <div style="display:grid; grid-template-columns:auto auto 50px;grid-gap:5px;">
                                        <div style="display:grid; grid-template-columns:15px auto; grid-gap:5px; align-items:center">
                                            <div>
                                                <i class="fas fa-plus my_color_00338e"></i>
                                            </div>
                                            <div>
                                                <span class="my_color_00338e ZAfont_family" style="font-weight:600; font-size:18px"> {{$t('3067')}}:</span>
                                                <span class="ZAfont_family" style="font-weight:600; color:#000000; font-size:20px"> {{summary.elevatorMotor_TemperatureMonitoring.typeDesignation}}</span>
                                            </div>
                                        </div>
                                        <div v-if="information.data.price.motor_Option_TemperatureMonitoring.display==true && information.data.price.motor_Option_TemperatureMonitoring.valid==true" class="ZAfont_family ZAfont_size my_color_00338e" style="margin-left: auto; margin-right: 0; width: 260px; display: grid; grid-template-columns: 1fr 1fr 1fr; grid-gap: 5px; text-align: right; font-weight: 600;">
                                            <span class="my_summaryInfo"><span v-if="information.data.price.motor_Option_TemperatureMonitoring.grossPrice!=null">{{isDataANumber(information.data.price.motor_Option_TemperatureMonitoring.grossPrice,'€')}}</span></span>
                                            <span class="my_summaryInfo"><span v-if="information.data.price.motor_Option_TemperatureMonitoring.discount !=null">{{isDataANumber(information.data.price.motor_Option_TemperatureMonitoring.discount,'%')}}</span></span>
                                            <span class="my_summaryInfo"><span v-if="information.data.price.motor_Option_TemperatureMonitoring.netPrice!=null">{{isDataANumber(information.data.price.motor_Option_TemperatureMonitoring.netPrice,'€')}}</span></span>
                                        </div>
                                        <div v-if="information.data.price.motor_Option_TemperatureMonitoring.valid==false" class="ZAfont_family ZAfont_size my_color_00338e" style="margin-left: auto; margin-right: 0; width: 260px; display: grid; grid-template-columns: 1fr 1fr 1fr; grid-gap: 5px; text-align: right; font-weight: 600;">
                                            <span class="my_summaryInfo"><span> </span></span>
                                            <span class="my_summaryInfo"><span> </span></span>
                                            <span class="my_summaryInfo">Auf Anfrage</span>
                                        </div>
                                        <div class="ZAfont_family ZAfont_size">
                                            <span v-if="showStoredInformation==true && storedInformation.price.motor_Option_TemperatureMonitoring.display==true && storedInformation.price.motor_Option_TemperatureMonitoring.netPrice!=null">
                                                <span v-if="storedInformation.price.motor_Option_TemperatureMonitoring.valid==true && storedInformation.price.motor_Option_TemperatureMonitoring.netPrice!=null"> ({{isDataANumber(storedInformation.price.motor_Option_TemperatureMonitoring.netPrice,'€')}})</span>
                                                <span v-if="storedInformation.price.motor_Option_TemperatureMonitoring.valid==false"> (Auf Anfrage)</span>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div v-if="summary.elevatorMotor_ForcedVentilation != null">
                                    <hr />
                                    <div style="display:grid; grid-template-columns:auto auto 50px;grid-gap:5px;">
                                        <div style="display:grid; grid-template-columns:15px auto; grid-gap:5px; align-items:center">
                                            <div>
                                                <i class="fas fa-plus my_color_00338e"></i>
                                            </div>
                                            <div>
                                                <span class="my_color_00338e ZAfont_family" style="font-weight:600; font-size:18px"> {{$t('298')}}:</span>
                                                <span v-if="((summary.elevatorMotor_ForcedVentilation.necessary==true)||((summary.elevatorMotor_ForcedVentilation.necessary==false)&&(summary.elevatorMotor_ForcedVentilation.selected == true)))" class="ZAfont_family" style="font-weight:600; color:#000000; font-size:20px"> {{$t('1035')}}</span>
                                                <span v-if="((summary.elevatorMotor_ForcedVentilation.necessary==false)&&(summary.elevatorMotor_ForcedVentilation.selected == false))" class="ZAfont_family" style="font-weight:600; color:#000000; font-size:20px"> {{$t('1036')}}</span>
                                            </div>
                                            <!--<div style="max-width:275px; text-align:right">-->
                                            <!--<a class="ZAfont_family" href="#">{{$t('3196')}}</a>-->
                                            <!--</div>-->
                                        </div>
                                        <div v-if="information.data.price.motor_Option_Fan.display==true && information.data.price.motor_Option_Fan.valid==true" class="ZAfont_family ZAfont_size my_color_00338e" style="margin-left: auto; margin-right: 0; width: 260px; display: grid; grid-template-columns: 1fr 1fr 1fr; grid-gap: 5px; text-align: right; font-weight: 600;">
                                            <span class="my_summaryInfo"><span v-if="information.data.price.motor_Option_Fan.grossPrice!=null">{{isDataANumber(information.data.price.motor_Option_Fan.grossPrice,'€')}}</span></span>
                                            <span class="my_summaryInfo"><span v-if="information.data.price.motor_Option_Fan.discount !=null">{{isDataANumber(information.data.price.motor_Option_Fan.discount,'%')}}</span></span>
                                            <span class="my_summaryInfo"><span v-if="information.data.price.motor_Option_Fan.netPrice!=null">{{isDataANumber(information.data.price.motor_Option_Fan.netPrice,'€')}}</span></span>
                                        </div>
                                        <div v-if="information.data.price.motor_Option_Fan.valid==false" class="ZAfont_family ZAfont_size my_color_00338e" style="margin-left: auto; margin-right: 0; width: 260px; display: grid; grid-template-columns: 1fr 1fr 1fr; grid-gap: 5px; text-align: right; font-weight: 600;">
                                            <span class="my_summaryInfo"><span> </span></span>
                                            <span class="my_summaryInfo"><span> </span></span>
                                            <span class="my_summaryInfo">Auf Anfrage</span>
                                        </div>
                                        <div class="ZAfont_family ZAfont_size">
                                            <span v-if="showStoredInformation==true && storedInformation.price.motor_Option_Fan.display==true && storedInformation.price.motor_Option_Fan.netPrice!=null">
                                                <span v-if="storedInformation.price.motor_Option_Fan.valid==true && storedInformation.price.motor_Option_Fan.netPrice!=null"> ({{isDataANumber(storedInformation.price.motor_Option_Fan.netPrice,'€')}})</span>
                                                <span v-if="storedInformation.price.motor_Option_Fan.valid==false"> (Auf Anfrage)</span>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div v-if="summary.elevatorMotor_Brake != null">
                                    <hr />
                                    <!--<div style="display:grid; grid-template-columns:auto auto;grid-gap:5px;border: 1px solid green">-->
                                    <div style="display:grid; grid-template-columns:15px auto; grid-gap:5px; align-items:center;">
                                        <div>
                                            <i class="fas fa-plus my_color_00338e"></i>
                                        </div>
                                        <div>
                                            <span class="my_color_00338e ZAfont_family" style="font-weight:600; font-size:18px"> {{$t('3260')}}:</span>
                                            <span class="ZAfont_family" style="font-weight:600; color:#000000; font-size:20px"> {{summary.elevatorMotor_Brake.typeDesignation}}</span>
                                        </div>

                                        <div class="ZAfont_family ZAfont_size" style="grid-column: 2 / span 1">
                                            <div>{{$t('1313')}}: {{ValueDecimalSeparator(summary.elevatorMotor_Brake.brakingDistance)}} m</div>
                                            <div>{{$t('3073')}}: {{summary.elevatorMotor_Brake.nominalVoltage}} V</div>
                                        </div>
                                        <div class="ZAfont_family ZAfont_size" style="grid-column: 2 / span 1">
                                            <div style="display:grid; grid-template-columns:auto auto 50px;grid-gap:5px;">
                                                <div>
                                                    <div v-if="(summary.elevatorMotor_Brake.mechanicalRelease =='without')">{{$t('3045')}}: {{$t('1425')}}</div>
                                                    <div v-if="(summary.elevatorMotor_Brake.mechanicalRelease =='hand release')">{{$t('3045')}}: {{$t('1426')}}</div>
                                                    <div v-if="(summary.elevatorMotor_Brake.mechanicalRelease =='bowden cable hand release')">{{$t('3045')}}: {{$t('1427')}}</div>
                                                    <div v-if="(summary.elevatorMotor_Brake.mechanicalRelease =='without bowden cable hand release')">{{$t('3045')}}: {{$t('1428')}}</div>
                                                    <div v-if="(summary.elevatorMotor_Brake.mechanicalRelease =='prepare for bowden cable hand release')">{{$t('3045')}}: {{$t('1453')}}</div>
                                                </div>

                                                <div v-if="information.data.price.motor_Option_BrakeRelease.display==true && information.data.price.motor_Option_BrakeRelease.valid==true" class="ZAfont_family ZAfont_size my_color_00338e" style="margin-left: auto; margin-right: 0; width: 260px; display: grid; grid-template-columns: 1fr 1fr 1fr; grid-gap: 5px; text-align: right; font-weight: 600;">
                                                    <span class="my_summaryInfo"><span v-if="information.data.price.motor_Option_BrakeRelease.grossPrice!=null">{{isDataANumber(information.data.price.motor_Option_BrakeRelease.grossPrice,'€')}}</span></span>
                                                    <span class="my_summaryInfo"><span v-if="information.data.price.motor_Option_BrakeRelease.discount !=null">{{isDataANumber(information.data.price.motor_Option_BrakeRelease.discount,'%')}}</span></span>
                                                    <span class="my_summaryInfo"><span v-if="information.data.price.motor_Option_BrakeRelease.netPrice!=null">{{isDataANumber(information.data.price.motor_Option_BrakeRelease.netPrice,'€')}}</span></span>
                                                </div>
                                                <div v-if="information.data.price.motor_Option_BrakeRelease.valid==false" class="ZAfont_family ZAfont_size my_color_00338e" style="margin-left: auto; margin-right: 0; width: 260px; display: grid; grid-template-columns: 1fr 1fr 1fr; grid-gap: 5px; text-align: right; font-weight: 600;">
                                                    <span class="my_summaryInfo"><span> </span></span>
                                                    <span class="my_summaryInfo"><span> </span></span>
                                                    <span class="my_summaryInfo">Auf Anfrage</span>
                                                </div>
                                                <div class="ZAfont_family ZAfont_size">
                                                    <span v-if="showStoredInformation==true && storedInformation.price.motor_Option_BrakeRelease.display==true && storedInformation.price.motor_Option_BrakeRelease.netPrice!=null">
                                                        <span v-if="storedInformation.price.motor_Option_BrakeRelease.valid==true && storedInformation.price.motor_Option_BrakeRelease.netPrice!=null"> ({{isDataANumber(storedInformation.price.motor_Option_BrakeRelease.netPrice,'€')}})</span>
                                                        <span v-if="storedInformation.price.motor_Option_BrakeRelease.valid==false"> (Auf Anfrage)</span>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="ZAfont_family ZAfont_size" style="grid-column: 2 / span 1">
                                            <div style="display:grid; grid-template-columns:auto auto 50px;grid-gap:5px;">
                                                <div>
                                                    <div v-if="(summary.elevatorMotor_Brake.releaseMonitoring =='microswitch')">{{$t('208')}}: {{$t('1429')}}</div>
                                                    <div v-if="(summary.elevatorMotor_Brake.releaseMonitoring =='proximity switch')">{{$t('208')}}: {{$t('1430')}}</div>
                                                </div>
                                                <div v-if="information.data.price.motor_Option_BrakeMonitoring.display==true && information.data.price.motor_Option_BrakeMonitoring.valid==true" class="ZAfont_family ZAfont_size my_color_00338e" style="margin-left: auto; margin-right: 0; width: 260px; display: grid; grid-template-columns: 1fr 1fr 1fr; grid-gap: 5px; text-align: right; font-weight: 600;">
                                                    <span class="my_summaryInfo"><span v-if="information.data.price.motor_Option_BrakeMonitoring.grossPrice!=null">{{isDataANumber(information.data.price.motor_Option_BrakeMonitoring.grossPrice,'€')}}</span></span>
                                                    <span class="my_summaryInfo"><span v-if="information.data.price.motor_Option_BrakeMonitoring.discount !=null">{{isDataANumber(information.data.price.motor_Option_BrakeMonitoring.discount,'%')}}</span></span>
                                                    <span class="my_summaryInfo"><span v-if="information.data.price.motor_Option_BrakeMonitoring.netPrice!=null">{{isDataANumber(information.data.price.motor_Option_BrakeMonitoring.netPrice,'€')}}</span></span>
                                                </div>
                                                <div v-if="information.data.price.motor_Option_BrakeMonitoring.valid==false" class="ZAfont_family ZAfont_size my_color_00338e" style="margin-left: auto; margin-right: 0; width: 260px; display: grid; grid-template-columns: 1fr 1fr 1fr; grid-gap: 5px; text-align: right; font-weight: 600;">
                                                    <span class="my_summaryInfo"><span> </span></span>
                                                    <span class="my_summaryInfo"><span> </span></span>
                                                    <span class="my_summaryInfo">Auf Anfrage</span>
                                                </div>
                                                <div class="ZAfont_family ZAfont_size">
                                                    <span v-if="showStoredInformation==true && storedInformation.price.motor_Option_BrakeMonitoring.display==true && storedInformation.price.motor_Option_BrakeMonitoring.netPrice!=null">
                                                        <span v-if="storedInformation.price.motor_Option_BrakeMonitoring.valid==true && storedInformation.price.motor_Option_BrakeMonitoring.netPrice!=null"> ({{isDataANumber(storedInformation.price.motor_Option_BrakeMonitoring.netPrice,'€')}})</span>
                                                        <span v-if="storedInformation.price.motor_Option_BrakeMonitoring.valid==false"> (Auf Anfrage)</span>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div v-if="summary.elevatorMotor_Encoder != null">
                                    <hr />
                                    <div style="display:grid; grid-template-columns:auto auto 50px;grid-gap:5px;">
                                        <div style="display:grid; grid-template-columns:15px auto; grid-gap:5px; align-items:center">
                                            <div>
                                                <i class="fas fa-plus my_color_00338e"></i>
                                            </div>
                                            <div>
                                                <span class="my_color_00338e ZAfont_family" style="font-weight:600; font-size:18px"> {{$t('3075')}}:</span>
                                                <span class="ZAfont_family" style="font-weight:600; color:#000000; font-size:20px"> {{summary.elevatorMotor_Encoder.typeDesignation}}</span>
                                            </div>
                                        </div>
                                        <div v-if="information.data.price.motor_Option_Encoder.display==true && information.data.price.motor_Option_Encoder.valid==true" class="ZAfont_family ZAfont_size my_color_00338e" style="margin-left: auto; margin-right: 0; width: 260px; display: grid; grid-template-columns: 1fr 1fr 1fr; grid-gap: 5px; text-align: right; font-weight: 600;">
                                            <span class="my_summaryInfo"><span v-if="information.data.price.motor_Option_Encoder.grossPrice!=null">{{isDataANumber(information.data.price.motor_Option_Encoder.grossPrice,'€')}}</span></span>
                                            <span class="my_summaryInfo"><span v-if="information.data.price.motor_Option_Encoder.discount !=null">{{isDataANumber(information.data.price.motor_Option_Encoder.discount,'%')}}</span></span>
                                            <span class="my_summaryInfo"><span v-if="information.data.price.motor_Option_Encoder.netPrice!=null">{{isDataANumber(information.data.price.motor_Option_Encoder.netPrice,'€')}}</span></span>
                                        </div>
                                        <div v-if="information.data.price.motor_Option_Encoder.valid==false" class="ZAfont_family ZAfont_size my_color_00338e" style="margin-left: auto; margin-right: 0; width: 260px; display: grid; grid-template-columns: 1fr 1fr 1fr; grid-gap: 5px; text-align: right; font-weight: 600;">
                                            <span class="my_summaryInfo"><span> </span></span>
                                            <span class="my_summaryInfo"><span> </span></span>
                                            <span class="my_summaryInfo">Auf Anfrage</span>
                                        </div>
                                        <div class="ZAfont_family ZAfont_size">
                                            <span v-if="showStoredInformation==true && storedInformation.price.motor_Option_Encoder.display==true && storedInformation.price.motor_Option_Encoder.netPrice!=null">
                                                <span v-if="storedInformation.price.motor_Option_Encoder.valid==true && storedInformation.price.motor_Option_Encoder.netPrice!=null"> ({{isDataANumber(storedInformation.price.motor_Option_Encoder.netPrice,'€')}})</span>
                                                <span v-if="storedInformation.price.motor_Option_Encoder.valid==false"> (Auf Anfrage)</span>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr />
                    <div v-if="za_employee">
                        <div v-if="information.data.price.totalPriceMotor.valid==true && information.data.price.totalPriceMotor.netPrice!=null" class="ZAfont_family ZAfont_size my_color_00338e" style="margin-left: auto; margin-right: 0; width: 260px; display: grid; grid-template-columns: 1fr 1fr 1fr 50px; grid-gap: 5px; text-align: right; font-weight: 600; ">
                            <span> Gesamt</span>
                            <span style="text-align: left"><span>Antrieb: </span></span>
                            <span>{{isDataANumber(information.data.price.totalPriceMotor.netPrice,'€')}}</span>
                            <span class="ZAfont_family ZAfont_size" style="color:black" v-if="showStoredInformation==true">
                                <span v-if="storedInformation.price.totalPriceMotor.valid==true && storedInformation.price.totalPriceMotor.netPrice!=null"> ({{isDataANumber(storedInformation.price.totalPriceMotor.netPrice,'€')}})</span>
                                <span v-if="storedInformation.price.totalPriceMotor.valid==false"> (Auf Anfrage)</span>
                            </span>
                        </div>
                        <div v-if="information.data.price.totalPriceMotor.valid==false || information.data.price.totalPriceMotor.netPrice==null" class="ZAfont_family ZAfont_size my_color_00338e" style="margin-left: auto; margin-right: 0; width: 260px; display: grid; grid-template-columns: 1fr 1fr 1fr 50px; grid-gap: 5px; text-align: right; font-weight: 600; ">
                            <span> Gesamt</span>
                            <span style="text-align: left"><span>Antrieb: </span></span>
                            <span>Auf Anfrage</span>
                            <span class="ZAfont_family ZAfont_size" style="color:black" v-if="showStoredInformation==true">
                                <span v-if="storedInformation.price.totalPriceMotor.valid==true && storedInformation.price.totalPriceMotor.netPrice!=null"> ({{isDataANumber(storedInformation.price.totalPriceMotor.netPrice,'€')}})</span>
                                <span v-if="storedInformation.price.totalPriceMotor.valid==false"> (Auf Anfrage)</span>
                            </span>
                        </div>
                    </div>
                </div>
                <div v-if="summary.inverter != null">
                    <div v-if="za_employee === false" class="ZAfont_family my_color_00338e" style="margin-top:60px;font-weight:600; font-size:1.5rem">{{$t('3025')}}</div>
                    <div v-if="za_employee && summary.inverter.typeDesignation != 'other inverter'" class="ZAfont_family my_color_00338e" style="font-weight: 600; font-size: 1.5rem; margin-top: 60px;display:grid; grid-template-columns:auto 260px">
                        <div style="text-align:left; float:left">{{$t('3025')}} </div>
                    </div>
                    <hr style="margin-top:0" />
                    <div>
                        <div style="display: grid; grid-template-columns: 150px auto; grid-column-gap: 5px;">
                            <div>
                                <Images :imageFilePath="summary.inverter_ImagePath" :maxImageHeight="80" :maxImageWidth="120"></Images>
                            </div>
                            <div>
                                <div style="display: grid; grid-template-columns: auto auto 50px; grid-column-gap: 5px;">
                                    <div>
                                        <div v-if="summary.inverter.typeDesignation === 'other inverter'">
                                            <span class="my_color_00338e ZAfont_family" style="font-weight:600; font-size:18px">{{$t('3197')}}: </span>
                                            <span class="ZAfont_family" style="font-weight:600; color:#000000; font-size:20px"> {{$t('3261')}}</span>
                                        </div>
                                        <template v-else>
                                            <div>
                                                <span class="my_color_00338e ZAfont_family" style="font-weight:600; font-size:18px">{{$t('3197')}}: </span>
                                                <span class="ZAfont_family" style="font-weight:600; color:#000000; font-size:20px"> {{summary.inverter.typeDesignation}}</span>
                                            </div>
                                        </template>
                                        <div class="ZAfont_family ZAfont_size">
                                            <div>{{$t('1381')}}: {{ValueDecimalSeparator(summary.inverter.nominalCurrent)}} A</div>
                                            <div>{{$t('3183')}}: {{ValueDecimalSeparator(summary.inverter.maximumOperatingCurrent)}} A</div>
                                            <div>{{$t('3184')}}: {{ValueDecimalSeparator(summary.inverter.acceleration)}} m/s²</div>
                                        </div>
                                    </div>
                                    <div v-if="information.data.price.inverter.display==true && information.data.price.inverter.valid==true" class="ZAfont_family ZAfont_size my_color_00338e" style="margin-left: auto; margin-right: 0; width: 260px; display: grid; grid-template-columns: 1fr 1fr 1fr; grid-gap: 5px; text-align: right; font-weight: 600;">
                                        <span class="my_summaryInfo"><span v-if="information.data.price.inverter.grossPrice!=null">{{isDataANumber(information.data.price.inverter.grossPrice,'€')}}</span></span>
                                        <span class="my_summaryInfo"><span v-if="information.data.price.inverter.discount !=null">{{isDataANumber(information.data.price.inverter.discount,'%')}}</span></span>
                                        <span class="my_summaryInfo"><span v-if="information.data.price.inverter.netPrice!=null">{{isDataANumber(information.data.price.inverter.netPrice,'€')}}</span></span>
                                    </div>
                                    <div v-if="information.data.price.inverter.valid==false" class="ZAfont_family ZAfont_size my_color_00338e" style="margin-left: auto; margin-right: 0; width: 260px; display: grid; grid-template-columns: 1fr 1fr 1fr; grid-gap: 5px; text-align: right; font-weight: 600;">
                                        <span class="my_summaryInfo"><span> </span></span>
                                        <span class="my_summaryInfo"><span> </span></span>
                                        <span class="my_summaryInfo">Auf Anfrage</span>
                                    </div>
                                    <div class="ZAfont_family ZAfont_size">
                                        <span v-if="showStoredInformation==true && storedInformation.price.inverter.display==true && storedInformation.price.inverter.netPrice!=null">
                                            <span v-if="storedInformation.price.inverter.valid==true && storedInformation.price.inverter.netPrice!=null"> ({{isDataANumber(storedInformation.price.inverter.netPrice,'€')}})</span>
                                            <span v-if="storedInformation.price.inverter.valid==false"> (Auf Anfrage)</span>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <hr />
                    </div>
                    <div v-if="summary.powerRecuperationUnit != null">
                        <div style="display: grid; grid-template-columns: 150px auto; grid-column-gap: 5px;">
                            <div>
                                <Images :imageFilePath="summary.powerRecuperationUnit_ImagePath" :maxImageHeight="80" :maxImageWidth="120"></Images>
                            </div>
                            <div>
                                <div style="display: grid; grid-template-columns: auto auto 50px; grid-column-gap: 5px;">
                                    <div>
                                        <div>
                                            <div>
                                                <span class="my_color_00338e ZAfont_family" style="font-weight:600; font-size:18px">{{$t('3163')}}: </span>
                                                <span class="ZAfont_family" style="font-weight:600; color:#000000; font-size:20px"> {{summary.powerRecuperationUnit.typeDesignation}}</span>
                                            </div>
                                        </div>
                                        <div class="ZAfont_family ZAfont_size">
                                            <div>{{$t('3113')}}: {{ValueDecimalSeparator(summary.powerRecuperationUnit.maximumRecuperationPower)}} W</div>
                                            <div>{{$t('3114')}}: {{ValueDecimalSeparator(summary.powerRecuperationUnit.maximumRecuperationCurrent)}} A</div>
                                        </div>
                                    </div>
                                    <div v-if="information.data.price.recuperationUnit.display==true && information.data.price.recuperationUnit.valid==true" class="ZAfont_family ZAfont_size my_color_00338e" style="margin-left: auto; margin-right: 0; width: 260px; display: grid; grid-template-columns: 1fr 1fr 1fr; grid-gap: 5px; text-align: right; font-weight: 600;">
                                        <span class="my_summaryInfo"><span v-if="information.data.price.recuperationUnit.grossPrice!=null">{{isDataANumber(information.data.price.recuperationUnit.grossPrice,'€')}}</span></span>
                                        <span class="my_summaryInfo"><span v-if="information.data.price.recuperationUnit.discount !=null">{{isDataANumber(information.data.price.recuperationUnit.discount,'%')}}</span></span>
                                        <span class="my_summaryInfo"><span v-if="information.data.price.recuperationUnit.netPrice!=null">{{isDataANumber(information.data.price.recuperationUnit.netPrice,'€')}}</span></span>
                                    </div>
                                    <div v-if="information.data.price.recuperationUnit.valid==false" class="ZAfont_family ZAfont_size my_color_00338e" style="margin-left: auto; margin-right: 0; width: 260px; display: grid; grid-template-columns: 1fr 1fr 1fr; grid-gap: 5px; text-align: right; font-weight: 600;">
                                        <span class="my_summaryInfo"><span> </span></span>
                                        <span class="my_summaryInfo"><span> </span></span>
                                        <span class="my_summaryInfo">Auf Anfrage</span>
                                    </div>
                                    <div class="ZAfont_family ZAfont_size">
                                        <span v-if="showStoredInformation==true && storedInformation.price.recuperationUnit.display==true && storedInformation.price.recuperationUnit.netPrice!=null">
                                            <span v-if="storedInformation.price.recuperationUnit.valid==true && storedInformation.price.recuperationUnit.netPrice!=null"> ({{isDataANumber(storedInformation.price.recuperationUnit.netPrice,'€')}})</span>
                                            <span v-if="storedInformation.price.recuperationUnit.valid==false"> (Auf Anfrage)</span>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <hr />
                    </div>
                    <div v-if="summary.brakeResistor != null">
                        <div style="display: grid; grid-template-columns: 150px auto; grid-column-gap: 5px;">
                            <div>
                                <Images :imageFilePath="summary.brakeResistor_ImagePath" :maxImageHeight="80" :maxImageWidth="120"></Images>

                            </div>
                            <div>
                                <div>
                                    <div style="display: grid; grid-template-columns: auto auto 50px; grid-column-gap: 5px;">
                                        <div>
                                            <div>
                                                <span class="my_color_00338e ZAfont_family" style="font-weight:600; font-size:18px">{{$t('388')}}: </span>
                                                <span class="ZAfont_family" style="font-weight:600; color:#000000; font-size:20px"> {{summary.brakeResistor.typeDesignation}}</span>
                                            </div>
                                        </div>
                                        <div v-if="information.data.price.brakeResistor.display==true && information.data.price.brakeResistor.valid==true" class="ZAfont_family ZAfont_size my_color_00338e" style="margin-left: auto; margin-right: 0; width: 260px; display: grid; grid-template-columns: 1fr 1fr 1fr; grid-gap: 5px; text-align: right; font-weight: 600;">
                                            <span class="my_summaryInfo"><span v-if="information.data.price.brakeResistor.grossPrice!=null">{{isDataANumber(information.data.price.brakeResistor.grossPrice,'€')}}</span></span>
                                            <span class="my_summaryInfo"><span v-if="information.data.price.brakeResistor.discount !=null">{{isDataANumber(information.data.price.brakeResistor.discount,'%')}}</span></span>
                                            <span class="my_summaryInfo"><span v-if="information.data.price.brakeResistor.netPrice!=null">{{isDataANumber(information.data.price.brakeResistor.netPrice,'€')}}</span></span>
                                        </div>
                                        <div v-if="information.data.price.brakeResistor.valid==false" class="ZAfont_family ZAfont_size my_color_00338e" style="margin-left: auto; margin-right: 0; width: 260px; display: grid; grid-template-columns: 1fr 1fr 1fr; grid-gap: 5px; text-align: right; font-weight: 600;">
                                            <span class="my_summaryInfo"><span> </span></span>
                                            <span class="my_summaryInfo"><span> </span></span>
                                            <span class="my_summaryInfo">Auf Anfrage</span>
                                        </div>
                                        <div class="ZAfont_family ZAfont_size">
                                            <span v-if="showStoredInformation==true && storedInformation.price.brakeResistor.display==true && storedInformation.price.brakeResistor.netPrice!=null">
                                                <span v-if="storedInformation.price.brakeResistor.valid==true && storedInformation.price.brakeResistor.netPrice!=null"> ({{isDataANumber(storedInformation.price.brakeResistor.netPrice,'€')}})</span>
                                                <span v-if="storedInformation.price.brakeResistor.valid==false"> (Auf Anfrage)</span>
                                            </span>
                                        </div>
                                    </div>

                                    <!--<div style="max-width:275px; text-align:right">-->
                                    <!--<div class="ZAfont_family" style="font-weight:600; color:#000000; font-size:24px">XXX,XX €</div>-->
                                    <!--<a class="ZAfont_family" href="#">{{$t('3196')}}</a>-->
                                    <!--</div>-->
                                </div>
                            </div>
                        </div>
                        <hr />
                    </div>
                    <div v-if="summary.brakeControl != null">
                        <div style="display: grid; grid-template-columns: 150px auto; grid-column-gap: 5px;">
                            <div>
                                <Images :imageFilePath="summary.brakeControl_ImagePath" :maxImageHeight="80" :maxImageWidth="120"></Images>
                            </div>
                            <div>
                                <div style="display: grid; grid-template-columns: auto auto 50px; grid-column-gap: 5px;">
                                    <div>
                                        <div>
                                            <div>
                                                <span class="my_color_00338e ZAfont_family" style="font-weight:600; font-size:18px">{{$t('3198')}}: </span>
                                                <span class="ZAfont_family" style="font-weight:600; color:#000000; font-size:20px"> {{summary.brakeControl.typeDesignation}}</span>
                                            </div>
                                        </div>
                                        <div class="ZAfont_family ZAfont_size">
                                            <div>{{$t('3116')}}: {{ValueDecimalSeparator(summary.brakeControl.operatingVoltageSafetyCircuit)}} V</div>
                                            <div>{{$t('3199')}}: {{ValueDecimalSeparator(summary.brakeControl.ratedPowerAtOutputVoltage)}} W</div>
                                        </div>
                                    </div>
                                    <div v-if="information.data.price.brakeControl.display==true && information.data.price.brakeControl.valid==true" class="ZAfont_family ZAfont_size my_color_00338e" style="margin-left: auto; margin-right: 0; width: 260px; display: grid; grid-template-columns: 1fr 1fr 1fr; grid-gap: 5px; text-align: right; font-weight: 600;">
                                        <span class="my_summaryInfo"><span v-if="information.data.price.brakeControl.grossPrice!=null">{{isDataANumber(information.data.price.brakeControl.grossPrice,'€')}}</span></span>
                                        <span class="my_summaryInfo"><span v-if="information.data.price.brakeControl.discount !=null">{{isDataANumber(information.data.price.brakeControl.discount,'%')}}</span></span>
                                        <span class="my_summaryInfo"><span v-if="information.data.price.brakeControl.netPrice!=null">{{isDataANumber(information.data.price.brakeControl.netPrice,'€')}}</span></span>
                                    </div>
                                    <div v-if="information.data.price.brakeControl.valid==false" class="ZAfont_family ZAfont_size my_color_00338e" style="margin-left: auto; margin-right: 0; width: 260px; display: grid; grid-template-columns: 1fr 1fr 1fr; grid-gap: 5px; text-align: right; font-weight: 600;">
                                        <span class="my_summaryInfo"><span> </span></span>
                                        <span class="my_summaryInfo"><span> </span></span>
                                        <span class="my_summaryInfo">Auf Anfrage</span>
                                    </div>
                                    <div class="ZAfont_family ZAfont_size">
                                        <span v-if="showStoredInformation==true && storedInformation.price.brakeControl.display==true && storedInformation.price.brakeControl.netPrice!=null">
                                            <span v-if="storedInformation.price.brakeControl.valid==true && storedInformation.price.brakeControl.netPrice!=null"> ({{isDataANumber(storedInformation.price.brakeControl.netPrice,'€')}})</span>
                                            <span v-if="storedInformation.price.brakeControl.valid==false"> (Auf Anfrage)</span>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <hr />
                    </div>
                    <div v-if="za_employee">
                        <div></div>
                        <div v-if="information.data.price.totalPrice.valid==true && information.data.price.totalPrice.netPrice!=null" class="ZAfont_family ZAfont_size my_color_00338e" style="margin-left: auto; margin-right: 0; width: 260px; display: grid; grid-template-columns: 1fr 1fr 1fr 50px; grid-gap: 5px; text-align: right; font-weight: 600; ">
                            <span> Gesamt</span>
                            <span style="text-align: left"><span> </span></span>
                            <span>{{isDataANumber(information.data.price.totalPrice.netPrice,'€')}}</span>
                            <span class="ZAfont_family ZAfont_size" style="color:black" v-if="showStoredInformation==true">
                                <span v-if="storedInformation.price.totalPrice.valid==true && storedInformation.price.totalPrice.netPrice!=null"> ({{isDataANumber(storedInformation.price.totalPrice.netPrice,'€')}})</span>
                                <span v-if="storedInformation.price.totalPrice.valid==false"> (Auf Anfrage)</span>
                            </span>
                        </div>
                        <div v-if="information.data.price.totalPrice.valid==false || information.data.price.totalPrice.netPrice==null" class="ZAfont_family ZAfont_size my_color_00338e" style="margin-left: auto; margin-right: 0; width: 260px; display: grid; grid-template-columns: 1fr 1fr 1fr 50px; grid-gap: 5px; text-align: right; font-weight: 600; ">
                            <span> Gesamt</span>
                            <span style="text-align: left"><span> </span></span>
                            <span>Auf Anfrage</span>
                            <span class="ZAfont_family ZAfont_size" style="color:black" v-if="showStoredInformation==true">
                                <span v-if="storedInformation.price.totalPrice.valid==true && storedInformation.price.totalPrice.netPrice!=null"> ({{isDataANumber(storedInformation.price.totalPrice.netPrice,'€')}})</span>
                                <span v-if="storedInformation.price.totalPrice.valid==false"> (Auf Anfrage)</span>
                            </span>
                        </div>
                    </div>
                </div>
                <div v-if="summary.frames != null">
                    <div class="ZAfont_family my_color_00338e" style="margin-top:60px;font-weight:600; font-size:1.5rem">{{$t('3255')}} {{$t('179')}} {{$t('1016')}} </div>
                    <hr style="margin-top:0" />
                    <div>
                        <div style="display: grid; grid-template-columns: 150px auto; grid-column-gap: 5px;">
                            <div>
                                <Images :imageFilePath="summary.frame_ImagePath" :maxImageHeight="80" :maxImageWidth="120"></Images>
                            </div>
                            <div>
                                <div style="display: grid; grid-template-columns: auto auto 50px; grid-column-gap: 5px;">
                                    <div>
                                        <span class="my_color_00338e ZAfont_family" style="font-weight:600; font-size:18px">{{$t('3255')}}: </span>
                                        <span class="ZAfont_family" style="font-weight:600; color:#000000; font-size:20px"> {{summary.frames.type}}</span>

                                        <div class="ZAfont_family ZAfont_size">
                                            <div v-if="summary.frame_TypeDesignation==null || summary.frame_TypeDesignation.useTextKey==false || summary.frame_TypeDesignation.textKey==null">{{$t('407')}}: {{summary.frames.typeDesignation}}</div>
                                            <div v-if="summary.frame_TypeDesignation!=null && summary.frame_TypeDesignation.useTextKey==true && summary.frame_TypeDesignation.textKey!=null">{{$t('407')}}: {{$t(summary.frame_TypeDesignation.textKey)}}</div>
                                            <div>{{$t('407')}}: {{summary.frames.type}}</div>
                                            <div>{{$t('408')}}: {{summary.frames.axleLoad}} kg</div>
                                            <div>{{$t('1026')}}: {{summary.suspensionMeans.verticalDistanceOfTheAxlesFromTractionSheaveAndDeflectionPulley}}</div>
                                            <div>{{$t('1027')}}: {{summary.suspensionMeans.horizontalDistanceOfTheRopeHoles}}</div>
                                            <div>{{$t('1193')}}: {{summary.frames.dimensionSheet}}</div>
                                            <div v-if="summary.frame_TypeDesignation!=null && (summary.frame_TypeDesignation.useTextKey==false || summary.frame_TypeDesignation.textKey==null) && summary.frame_TypeDesignation.text!=null">{{$t('407')}}: {{summary.frames.typeDesignation.text}}</div>
                                            <div v-if="summary.frame_PositionPulley!=null && summary.frame_PositionPulley.useTextKey==true && summary.frame_PositionPulley.textKey!=null">{{$t('3383')}}: {{$t(summary.frame_PositionPulley.textKey)}}</div>
                                        </div>
                                    </div>
                                    <div v-if="information.data.price.frame.display==true && information.data.price.frame.valid==true" class="ZAfont_family ZAfont_size my_color_00338e" style="margin-left: auto; margin-right: 0; width: 260px; display: grid; grid-template-columns: 1fr 1fr 1fr; grid-gap: 5px; text-align: right; font-weight: 600;">
                                        <span class="my_summaryInfo"><span v-if="information.data.price.frame.grossPrice!=null">{{isDataANumber(information.data.price.frame.grossPrice,'€')}}</span></span>
                                        <span class="my_summaryInfo"><span v-if="information.data.price.frame.discount !=null">{{isDataANumber(information.data.price.frame.discount,'%')}}</span></span>
                                        <span class="my_summaryInfo"><span v-if="information.data.price.frame.netPrice!=null">{{isDataANumber(information.data.price.frame.netPrice,'€')}}</span></span>
                                    </div>
                                    <div v-if="information.data.price.frame.valid==false" class="ZAfont_family ZAfont_size my_color_00338e" style="margin-left: auto; margin-right: 0; width: 260px; display: grid; grid-template-columns: 1fr 1fr 1fr; grid-gap: 5px; text-align: right; font-weight: 600;">
                                        <span class="my_summaryInfo"><span> </span></span>
                                        <span class="my_summaryInfo"><span> </span></span>
                                        <span class="my_summaryInfo">Auf Anfrage</span>
                                    </div>
                                    <div class="ZAfont_family ZAfont_size">
                                        <span v-if="showStoredInformation==true && storedInformation.price.frame.display==true && storedInformation.price.frame.netPrice!=null">
                                            <span v-if="storedInformation.price.frame.valid==true && storedInformation.price.frame.netPrice!=null"> ({{isDataANumber(storedInformation.price.frame.netPrice,'€')}})</span>
                                            <span v-if="storedInformation.price.frame.valid==false"> (Auf Anfrage)</span>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <hr />
                    </div>
                    <div v-if="summary.pulleys != null">
                        <div style="display: grid; grid-template-columns: 150px auto; grid-column-gap: 5px;">
                            <div>
                                <Images :imageFilePath="summary.pulley_ImagePath" :maxImageHeight="80" :maxImageWidth="120"></Images>
                            </div>
                            <div>
                                <div style="display: grid; grid-template-columns: auto auto 50px; grid-column-gap: 5px;">
                                    <div>
                                        <div>
                                            <div>
                                                <span v-if="summary.pulleys.numberOfPulleys>1" class="my_color_00338e ZAfont_family" style="font-weight:600; font-size:18px">2x </span>
                                                <span class="my_color_00338e ZAfont_family" style="font-weight:600; font-size:18px">{{$t('1062')}}: </span>
                                                <span v-if="summary.pulleys.type!='on request' && (summary.pulley_Type==null || summary.pulley_Type.useTextKey==false || summary.pulley_Type.textKey==null)" class="ZAfont_family" style="font-weight:600; color:#000000; font-size:20px"> {{summary.pulleys.type}}</span>
                                                <span v-if="summary.pulleys.type!='on request' && (summary.pulley_Type!=null && summary.pulley_Type.useTextKey==true && summary.pulley_Type.textKey!=null)" class="ZAfont_family" style="font-weight:600; color:#000000; font-size:20px"> {{$t(summary.pulley_Type.textKey)}}</span>
                                                <span v-if="summary.pulleys.type=='on request'" class="ZAfont_family" style="font-weight:600; color:#000000; font-size:20px"> {{$t('3378')}}</span>
                                            </div>
                                        </div>
                                        <div class="ZAfont_family ZAfont_size">
                                            <div>{{$t('3038')}}: {{summary.pulleys.diameter}} mm</div>
                                            <div v-if="summary.pulleys.type!='on request'">
                                                <span>{{$t('1182')}}: {{summary.pulleys.sheaveWidth}} mm</span>
                                            </div>
                                            <div v-if="summary.pulleys.type!='on request'">
                                                <span>{{$t('165')}}: {{summary.pulleys.numberOfGrooves}}</span>
                                                <span> x {{ValueDecimalSeparator(summary.pulleys.grooveDiameterMinimum)}}</span>
                                                <span v-if="summary.pulleys.grooveDiameterMinimum==summary.pulleys.grooveDiameterMaximum"> mm</span>
                                                <span v-if="summary.pulleys.grooveDiameterMinimum!=summary.pulleys.grooveDiameterMaximum"> / {{ValueDecimalSeparator(summary.pulleys.grooveDiameterMaximum)}} mm</span>
                                            </div>
                                            <div>
                                                <span>{{$t('1294')}}: {{summary.pulleys.grooveDistance}} mm</span>
                                            </div>
                                            <div v-if="summary.pulleys.type!='on request'">
                                                <span>{{$t('106')}}: {{summary.pulleys.maximumLoad}}</span>
                                            </div>
                                            <div v-if="summary.pulleys.type!='on request'">
                                                <span v-if="summary.pulley_Type==null || summary.pulley_Type.useTextKey==false || summary.pulley_Type.textKey==null">{{$t('407')}}: {{summary.pulleys.type}}</span>
                                                <span v-if="summary.pulley_Type!=null && summary.pulley_Type.useTextKey==true && summary.pulley_Type.textKey==null">{{$t('407')}}: {{$t(summary.pulley_Type.textKey)}}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div v-if="information.data.price.pulley.display==true && information.data.price.pulley.valid==true" class="ZAfont_family ZAfont_size my_color_00338e" style="margin-left: auto; margin-right: 0; width: 260px; display: grid; grid-template-columns: 1fr 1fr 1fr; grid-gap: 5px; text-align: right; font-weight: 600;">
                                        <span class="my_summaryInfo"><span v-if="information.data.price.pulley.grossPrice!=null">{{isDataANumber(information.data.price.pulley.grossPrice,'€')}}</span></span>
                                        <span class="my_summaryInfo"><span v-if="information.data.price.pulley.discount !=null">{{isDataANumber(information.data.price.pulley.discount,'%')}}</span></span>
                                        <span class="my_summaryInfo"><span v-if="information.data.price.pulley.netPrice!=null">{{isDataANumber(information.data.price.pulley.netPrice,'€')}}</span></span>
                                    </div>
                                    <div v-if="information.data.price.pulley.valid==false" class="ZAfont_family ZAfont_size my_color_00338e" style="margin-left: auto; margin-right: 0; width: 260px; display: grid; grid-template-columns: 1fr 1fr 1fr; grid-gap: 5px; text-align: right; font-weight: 600;">
                                        <span class="my_summaryInfo"><span> </span></span>
                                        <span class="my_summaryInfo"><span> </span></span>
                                        <span class="my_summaryInfo">Auf Anfrage</span>
                                    </div>
                                    <div class="ZAfont_family ZAfont_size">
                                        <span v-if="showStoredInformation==true && storedInformation.price.pulley.display==true">
                                            <span v-if="storedInformation.price.pulley.valid==true && storedInformation.price.pulley.net!=null"> ({{isDataANumber(storedInformation.price.pulley.netPrice,'€')}})</span>
                                            <span v-if="storedInformation.price.pulley.valid==false"> (Auf Anfrage)</span>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <hr />
                    </div>
                    <div v-if="summary.frameDeliverOptions != null && showFrameOptions == true">
                        <div style="display: grid; grid-template-columns: 150px auto; grid-column-gap: 5px;">
                            <div>
                                <Images :imageFilePath="summary.frameOptions_ImagePath" :maxImageHeight="80" :maxImageWidth="120"></Images>
                            </div>
                            <div>
                                <div>
                                    <div style="display: grid; grid-template-columns: auto auto 50px; grid-column-gap: 5px;">
                                        <div>
                                            <div>
                                                <span class="my_color_00338e ZAfont_family" style="font-weight:600; font-size:18px">{{$t('1016')}}: </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="ZAfont_family ZAfont_size">
                                        <div v-if="summary.frameDeliverOptions.motorConsole=='frame'">{{$t('3396')}}: {{$t('3381')}}</div>
                                        <div v-if="summary.frameDeliverOptions.motorConsole=='motor'">{{$t('3396')}}: {{$t('3382')}}</div>
                                        <!--<div v-if="summary.frameDeliverOptions.selectedOperatingInstructions!=null && summary.frameDeliverOptions.selectedOperatingInstructions==true">
        <span>{{$t('3390')}}<span v-if="summary.frameOperatingInstructionKey>0"> {{$t(summary.frameOperatingInstructionKey)}}</span></span>
    </div>-->
                                        <div v-if="summary.frameDeliverOptions.selectedAssembled!=null && summary.frameDeliverOptions.selectedAssembled==true" class="ZAfont_family ZAfont_size" style="grid-column: 2 / span 1">
                                            <div style="display:grid; grid-template-columns:auto auto 50px;grid-gap:5px;">
                                                <div>
                                                    <div>
                                                        <span>{{$t('3384')}}</span>
                                                    </div>
                                                </div>
                                                <div v-if="information.data.price.frame_Option_Assembled.display==true && information.data.price.frame_Option_Assembled.valid==true" class="ZAfont_family ZAfont_size my_color_00338e" style="margin-left: auto; margin-right: 0; width: 260px; display: grid; grid-template-columns: 1fr 1fr 1fr; grid-gap: 5px; text-align: right; font-weight: 600;">
                                                    <span class="my_summaryInfo"><span v-if="information.data.price.frame_Option_Assembled.grossPrice!=null">{{isDataANumber(information.data.price.frame_Option_Assembled.grossPrice,'€')}}</span></span>
                                                    <span class="my_summaryInfo"><span v-if="information.data.price.frame_Option_Assembled.discount !=null">{{isDataANumber(information.data.price.frame_Option_Assembled.discount,'%')}}</span></span>
                                                    <span class="my_summaryInfo"><span v-if="information.data.price.frame_Option_Assembled.netPrice!=null">{{isDataANumber(information.data.price.frame_Option_Assembled.netPrice,'€')}}</span></span>
                                                </div>
                                                <div v-if="information.data.price.frame_Option_Assembled.valid==false" class="ZAfont_family ZAfont_size my_color_00338e" style="margin-left: auto; margin-right: 0; width: 260px; display: grid; grid-template-columns: 1fr 1fr 1fr; grid-gap: 5px; text-align: right; font-weight: 600;">
                                                    <span class="my_summaryInfo"><span> </span></span>
                                                    <span class="my_summaryInfo"><span> </span></span>
                                                    <span class="my_summaryInfo">Auf Anfrage</span>
                                                </div>
                                                <div class="ZAfont_family ZAfont_size">
                                                    <span v-if="showStoredInformation==true && storedInformation.price.frame_Option_Assembled.display==true && storedInformation.price.frame_Option_Assembled.netPrice!=null">
                                                        <span v-if="storedInformation.price.frame_Option_Assembled.valid==true && storedInformation.price.frame_Option_Assembled.netPrice!=null"> ({{isDataANumber(storedInformation.price.frame_Option_Assembled.netPrice,'€')}})</span>
                                                        <span v-if="storedInformation.price.frame_Option_Assembled.valid==false"> (Auf Anfrage)</span>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                        <div v-if="summary.frameDeliverOptions.selectedEmergencyStopSwitch!=null && summary.frameDeliverOptions.selectedEmergencyStopSwitch==true" class="ZAfont_family ZAfont_size" style="grid-column: 2 / span 1">
                                            <div style="display:grid; grid-template-columns:auto auto 50px;grid-gap:5px;">
                                                <div>
                                                    <div>
                                                        <span>{{$t('3387')}}</span>
                                                    </div>
                                                </div>
                                                <div v-if="information.data.price.frame_Option_EmergencyStopSwitch.display==true && information.data.price.frame_Option_EmergencyStopSwitch.valid==true" class="ZAfont_family ZAfont_size my_color_00338e" style="margin-left: auto; margin-right: 0; width: 260px; display: grid; grid-template-columns: 1fr 1fr 1fr; grid-gap: 5px; text-align: right; font-weight: 600;">
                                                    <span class="my_summaryInfo"><span v-if="information.data.price.frame_Option_EmergencyStopSwitch.grossPrice!=null">{{isDataANumber(information.data.price.frame_Option_EmergencyStopSwitch.grossPrice,'€')}}</span></span>
                                                    <span class="my_summaryInfo"><span v-if="information.data.price.frame_Option_EmergencyStopSwitch.discount !=null">{{isDataANumber(information.data.price.frame_Option_EmergencyStopSwitch.discount,'%')}}</span></span>
                                                    <span class="my_summaryInfo"><span v-if="information.data.price.frame_Option_EmergencyStopSwitch.netPrice!=null">{{isDataANumber(information.data.price.frame_Option_EmergencyStopSwitch.netPrice,'€')}}</span></span>
                                                </div>
                                                <div v-if="information.data.price.frame_Option_EmergencyStopSwitch.valid==false" class="ZAfont_family ZAfont_size my_color_00338e" style="margin-left: auto; margin-right: 0; width: 260px; display: grid; grid-template-columns: 1fr 1fr 1fr; grid-gap: 5px; text-align: right; font-weight: 600;">
                                                    <span class="my_summaryInfo"><span> </span></span>
                                                    <span class="my_summaryInfo"><span> </span></span>
                                                    <span class="my_summaryInfo">Auf Anfrage</span>
                                                </div>
                                                <div class="ZAfont_family ZAfont_size">
                                                    <span v-if="showStoredInformation==true && storedInformation.price.frame_Option_EmergencyStopSwitch.display==true && storedInformation.price.frame_Option_EmergencyStopSwitch.netPrice!=null">
                                                        <span v-if="storedInformation.price.frame_Option_EmergencyStopSwitch.valid==true && storedInformation.price.frame_Option_EmergencyStopSwitch.netPrice!=null"> ({{isDataANumber(storedInformation.price.frame_Option_EmergencyStopSwitch.netPrice,'€')}})</span>
                                                        <span v-if="storedInformation.price.frame_Option_EmergencyStopSwitch.valid==false"> (Auf Anfrage)</span>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                        <div v-if="summary.frameDeliverOptions.selectedRopeGuard!=null && summary.frameDeliverOptions.selectedRopeGuard==true" class="ZAfont_family ZAfont_size" style="grid-column: 2 / span 1">
                                            <div style="display:grid; grid-template-columns:auto auto 50px;grid-gap:5px;">
                                                <div>
                                                    <div>
                                                        <span>{{$t('3388')}}</span>
                                                    </div>
                                                </div>
                                                <div v-if="information.data.price.frame_Option_RopeProtection.display==true && information.data.price.frame_Option_RopeProtection.valid==true" class="ZAfont_family ZAfont_size my_color_00338e" style="margin-left: auto; margin-right: 0; width: 260px; display: grid; grid-template-columns: 1fr 1fr 1fr; grid-gap: 5px; text-align: right; font-weight: 600;">
                                                    <span class="my_summaryInfo"><span v-if="information.data.price.frame_Option_RopeProtection.grossPrice!=null">{{isDataANumber(information.data.price.frame_Option_RopeProtection.grossPrice,'€')}}</span></span>
                                                    <span class="my_summaryInfo"><span v-if="information.data.price.frame_Option_RopeProtection.discount !=null">{{isDataANumber(information.data.price.frame_Option_RopeProtection.discount,'%')}}</span></span>
                                                    <span class="my_summaryInfo"><span v-if="information.data.price.frame_Option_RopeProtection.netPrice!=null">ri{{isDataANumber(information.data.price.frame_Option_RopeProtection.netPrice,'€')}}</span></span>
                                                </div>
                                                <div v-if="information.data.price.frame_Option_RopeProtection.valid==false" class="ZAfont_family ZAfont_size my_color_00338e" style="margin-left: auto; margin-right: 0; width: 260px; display: grid; grid-template-columns: 1fr 1fr 1fr; grid-gap: 5px; text-align: right; font-weight: 600;">
                                                    <span class="my_summaryInfo"><span> </span></span>
                                                    <span class="my_summaryInfo"><span> </span></span>
                                                    <span class="my_summaryInfo">Auf Anfrage</span>
                                                </div>
                                                <div class="ZAfont_family ZAfont_size">
                                                    <span v-if="showStoredInformation==true && storedInformation.price.frame_Option_RopeProtection.display==true && storedInformation.price.frame_Option_RopeProtection.netPrice!=null">
                                                        <span v-if="storedInformation.price.frame_Option_RopeProtection.valid==true && storedInformation.price.frame_Option_RopeProtection.netPrice!=null"> ({{isDataANumber(storedInformation.price.frame_Option_RopeProtection.netPrice,'€')}})</span>
                                                        <span v-if="storedInformation.price.frame_Option_RopeProtection.valid==false"> (Auf Anfrage)</span>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                        <!--<div v-if="summary.frameDeliverOptions.selectedAssembled!=null && summary.frameDeliverOptions.selectedAssembled==true">
        <span>{{$t('3384')}}</span>
    </div>-->
                                        <!--<div v-if="summary.frameDeliverOptions.selectedEmergencyStopSwitch!=null && summary.frameDeliverOptions.selectedEmergencyStopSwitch==true">
        <span>{{$t('3387')}}</span>
    </div>-->
                                        <!--<div v-if="summary.frameDeliverOptions.selectedIsolation!=null && summary.frameDeliverOptions.selectedIsolation==true">
        <span>{{$t('3389')}}</span>
    </div>-->
                                        <div v-if="summary.frameDeliverOptions.selectedRetrofit!=null && summary.frameDeliverOptions.selectedRetrofit==true">
                                            <span>{{$t('3386')}}</span>
                                        </div>
                                        <!--<div v-if="summary.frameDeliverOptions.selectedRopeGuard!=null && summary.frameDeliverOptions.selectedRopeGuard==true">
        <span>{{$t('3388')}}</span>
    </div>-->
                                    </div>
                                </div>
                            </div>
                        </div>
                        <hr />
                    </div>
                </div>

            </div>
            <div style="margin-left:0px; margin-right:0px">
                <SummaryInformation :additional="false"></SummaryInformation>
            </div>
        </div>

    </div>
</template>


<script>
    import router from '../../../router';
    import axios from 'axios';
    import Images from '../../configuration/Images';
    import SummaryInformation from './SummaryInformation.vue'
    import InvalidityReasons from '../../dataValidation/InvalidityReasons';
    import Modal from './SummaryEmailModal.vue'

    export default {
        name: 'Summary',
        components: {
            Images,
            SummaryInformation,
            InvalidityReasons,
            Modal,
        },
        data() {
            return {
                accept_terms_of_request: false,

                arrayCustomer: [],
                inputCust: '',
                arrCust: [],
                showModal: false,
                scrollYPosition: 0,
            }
        },
        watch: {
            async inputCust(val, oldVal) {
                if (val != oldVal && val.length >= 4 && val.length < 20) {
                    await axios({
                        method: 'post',
                        url: '/api/summaries/search_for_customers/' + this.inputCust
                    })
                        .then((response) => {
                            this.arrayCustomer = response.data.map(f => {
                                return [f[1], f[0], f[2]];
                            })
                        })
                } else {
                    if (val.length < 4) {
                        this.arrayCustomer = [];
                    }
                    ////this.$store.dispatch('summary/change_priceInformation_storeAction', {});
                    if (this.$store.state.summary.showInfoButton != null && this.$store.state.summary.showInfoButton == true && this.$store.state.summary.showInfo != null && this.$store.state.summary.showInfo == true && this.inputCust.indexOf("|") > -1) {
                        this.$store.dispatch('summary/get_PriceInformation_storeAction', this.inputCust);
                    }
                }
            }
        },
        computed: {
            sapProfile: {
                get() {
                    return this.$store.state.configurations.sap;
                }
            },
            userProfile: {
                get() {
                    return this.$store.state.account.user.profile
                }
            },
            configurationOverviewsId: {
                get() {
                    return this.$store.state.configurations.configurationOverviewsId;
                },
            },
            za_employee: {
                get() {
                    return this.$store.state.summary.showInfo;
                },
            },
            showPriceButton: {
                get() {
                    return this.$store.state.summary.showInfoButton;
                    //if (this.$store.state.summary.information != null && this.$store.state.summary.information.hasOwnProperty('data') && this.$store.state.summary.information.data != null && this.$store.state.summary.information.data.za_employee != null) {
                    //    return this.$store.state.summary.information.data.za_employee;
                    //}
                    //else {
                    //    return false;
                    //}
                },
            },
            accept_request_a_quote: {
                get() {
                    return this.userProfile.memberOf.includes('ZAEmployee');
                },
            },
            loaded_file_ZAlift_ID: {
                get() {
                    if (this.$store.state.configurations.loaded_configuration.hasOwnProperty('zAlift_ID')) {
                        return this.$store.state.configurations.loaded_configuration.zAlift_ID;
                    }
                    else {
                        return null;
                    }

                },
            },

            summary: {
                get() {
                    return this.$store.state.summary.summary;
                }
            },

            zAlift_ID: {
                get() {

                    var suffix = null;
                    if (this.$store.state.summary.summary != null && this.$store.state.summary.summary.hasOwnProperty('suffixData') && this.$store.state.summary.summary.suffixData != null) {
                        const firstIndex_whichMeetCondition = this.$store.state.summary.summary.suffixData.findIndex(i => i.selected == true);
                        if (firstIndex_whichMeetCondition > -1) {
                            suffix = this.$store.state.summary.summary.suffixData[firstIndex_whichMeetCondition].suffix;
                        }
                    }

                    if (suffix == null) {
                        return this.$store.state.summary.summary.zAlift_ID;
                    }
                    else {
                        return this.$store.state.summary.summary.zAlift_ID + "_V" + suffix;
                    }

                },
            },
            note: {
                get() {
                    return this.$store.state.summary.summary.note;
                },
            },
            fileLanguage: {
                get() {
                    return this.$store.state.summary.fileLanguage;
                },
                set(value) {
                    this.$store.dispatch('summary/change_fileLanguage_storeAction', value)
                }
            },
            information: {
                get() {
                    return this.$store.state.summary.information;
                },
            },
            showStoredInformation: {
                get() {
                    if (this.$store.state.summary.storedInformation != null && this.$store.state.summary.storedInformation.hasOwnProperty('showInformation') && this.$store.state.summary.storedInformation.showInformation == true) {
                        return true;
                    }
                    return false;
                },
            },
            storedInformation: {
                get() {
                    return this.$store.state.summary.storedInformation;
                },
            },
            showFrameOptions: {
                get() {
                    if (this.$store.state.summary.summary != null && this.$store.state.summary.summary.hasOwnProperty('frameDeliverOptions') && this.$store.state.summary.summary.frameDeliverOptions != null &&
                        (this.$store.state.summary.summary.frameDeliverOptions.hasOwnProperty('selectedRopeGuard') && this.$store.state.summary.summary.frameDeliverOptions.selectedRopeGuard != null && this.$store.state.summary.summary.frameDeliverOptions.selectedRopeGuard == true) ||
                        (this.$store.state.summary.summary.frameDeliverOptions.hasOwnProperty('selectedAssembled') && this.$store.state.summary.summary.frameDeliverOptions.selectedAssembled != null && this.$store.state.summary.summary.frameDeliverOptions.selectedAssembled == true) ||
                        (this.$store.state.summary.summary.frameDeliverOptions.hasOwnProperty('selectedEmergencyStopSwitch') && this.$store.state.summary.summary.frameDeliverOptions.selectedEmergencyStopSwitch != null && this.$store.state.summary.summary.frameDeliverOptions.selectedEmergencyStopSwitch == true) ||
                        (this.$store.state.summary.summary.frameDeliverOptions.hasOwnProperty('selectedRetrofit') && this.$store.state.summary.summary.frameDeliverOptions.selectedRetrofit != null && this.$store.state.summary.summary.frameDeliverOptions.selectedRetrofit == true)) {
                        return true;
                    }
                    else {
                        return false;
                    }
                },
            },
        },

        async created() {
            window.addEventListener('scroll', this.change_scrollYPosision);
            /**  Im moment wird für Kunden kein Angebot anfordern Button angezeigt  */

            //if (!this.userProfile.memberOf.includes('ZAEmployee')) {
            //    if (this.userProfile['customerRelations'] != '') {
            //        this.inputCust = JSON.parse(this.userProfile['customerRelations'])['CustomerRelations'][0]['ERPNumber'];

            //        this.loadCustomers(this.inputCust);
            //    }

            //}

            if (this.configurationOverviewsId != null) {
                this.$store.dispatch('summary/toDos_at_summary_storeAction');
            }
            else {
                this.$store.dispatch('configurations/toDos_after_routeSlug_UpdatedOrEntered_storeAction', 'summary')
            }

            this.$store.dispatch('summary/checkIf_input_UseFrame_Selected_storeAction');

            this.$store.dispatch('summary/get_PriceInformation_available_storeAction');

            //await axios({
            //    method: 'post',
            //    url: '/api/summaries/search_for_customers'
            //})
            //    .then((response) => {
            //        if (type == 'A') {
            //            alert("Ihre Angebotsnummer lautet: " + response.data);

            //        } else if (type == 'U') {
            //            alert("Ihre Auftragsnummer lautet: " + response.data);
            //        }
            //    })

            this.$store.dispatch('summary/change_ShowInfo_storAction', false);
        },

        destroyed() {
            window.removeEventListener('scroll', this.change_scrollYPosision);
        },

        methods: {
            change_scrollYPosision() {
                this.scrollYPosition = window.scrollY;
            },
            closeModal() {
                this.showModal = false;
            },
            async loadCustomers(inputCust) {

                await axios({
                    method: 'post',
                    url: '/api/summaries/search_for_customers/' + inputCust
                })
                    .then((response) => {
                        this.inputCust = "0 | " + response.data[0][1]['value'] + " | " + response.data[0][0]['value'] + " | " + response.data[0][2]['value'];
                    })

            },
            ValueDecimalSeparator(value) {
                if (value != null) {
                    const localDecimal = { value: value, country: this.$store.state.configurations.language, }
                    return this.$get_decimalValueToLocaleString(localDecimal);
                }
                return value;
            },
            PriceStatus(value) {
                if (this.$store.state.summary.information != null) {
                    if (value == 2001) {
                        return 3378;
                    }
                    else if (value == 2002) {
                        return 3376;
                    }
                }
            },
            isDataANumber(value, parameter1) {

                if (parameter1 == '&') {
                    if (value == null) {
                        return "";
                    }
                    else {
                        return String(this.ValueDecimalSeparator(value.toFixed(0)));
                    }

                }
                else {
                    if (value == null) {
                        return "";
                    }

                    else {
                        return String(this.ValueDecimalSeparator(value.toFixed(2)));
                    }

                }
            },

            newConfiguration() {
                var configurationOverviewsId = this.$store.state.configurations.configurationOverviewsId;
                var configurationSteps_inputs_Loaded = this.$store.state.configurations.configurationSteps.inputs.loaded;
                var configurationSteps_inputs_StoredInDatabase = this.$store.state.configurations.configurationSteps.inputs.storedInDatabase;

                this.$store.dispatch('driveTechnologies/change_driveTechnologies_toDefault_storeAction');
                this.$store.dispatch('controlTechnologies/change_controlTechnologies_toDefault_storeAction');
                this.$store.dispatch('frames/change_frames_toDefault_storeAction');
                this.$store.dispatch('summary/change_summary_toDefault_storeAction');
                this.$store.dispatch('configurations/change_configurations_toDefault_storeAction');

                var configurationSteps = Object.assign({}, this.$store.state.configurations.configurationSteps)
                var configurationSteps_inputs = Object.assign({}, this.$store.state.configurations.configurationSteps.inputs)
                configurationSteps_inputs.loaded = configurationSteps_inputs_Loaded;
                configurationSteps_inputs.storedInDatabase = configurationSteps_inputs_StoredInDatabase;
                configurationSteps.inputs = configurationSteps_inputs;
                this.$store.dispatch('configurations/change_configurationSteps_storeAction', configurationSteps);

                if ((configurationSteps.inputs.loaded == false) && (configurationSteps.inputs.storedInDatabase == true)) {
                    this.$store.dispatch('configurations/change_configurationOverviewsId_storeAction', configurationOverviewsId)
                }

                router.push({ params: { slug: 'inputs' } })
            },
            get_htmlFileContent() {
                this.$store.dispatch('summary/get_htmlFileContent_storeAction', false)
            },
            get_txtFileContent() {
                this.$store.dispatch('summary/get_txtFileContent_storeAction')
            },
            //furtherToCables() {
            //    this.$store.dispatch('configurations/change_manualConfiguration_storeAction', true)
            //    router.push({ params: { slug: 'cables' } });
            //},
            change_showInfo() {
                this.$store.dispatch('summary/change_ShowInfo_storeAction', this.inputCust);
            },
            request_offer_or_post_order(type) {
                var i = parseInt(this.inputCust.split('|')[0])
                if (!Number.isNaN(i)) {
                    var customerIdFromInput = this.inputCust.split('|')[1].trim();
                    var companyCustFromInput = this.inputCust.split('|')[3].trim();

                    const data = {
                        'configurationOverviewId': this.$store.state.configurations.configurationOverviewsId,
                        'documentType': type,
                        'customerID': customerIdFromInput,
                        'companyCust': companyCustFromInput,
                        'customerIDCust': '5054871',
                    }

                    axios({
                        method: 'post',
                        url: '/api/summaries/request_offer_or_post_order',
                        data: {
                            'data': data
                        }
                    })
                        .then((response) => {
                            if (type == 'A') {
                                alert("Ihre Angebotsnummer lautet: " + response.data);

                            } else if (type == 'U') {
                                alert("Ihre Auftragsnummer lautet: " + response.data);
                            }
                        })
                }
            },
            request_offer_or_post_order_sap(type) {
                //var i = parseInt(this.inputCust.split('|')[0])
                //if (!Number.isNaN(i)) {
                //    //console.log(this.arrayCustomer);
                //    //console.log(this.inputCust.split('|')[1].trim());
                //    var customerIdFromInput = this.inputCust.split('|')[1].trim();
                //    //console.log(this.inputCust.split('|')[3].trim());
                //    var companyCustFromInput = this.inputCust.split('|')[3].trim();

                const data = {
                    'configurationOverviewId': this.$store.state.configurations.configurationOverviewsId,
                    'documentType': type,
                    'customerID': '',//customerIdFromInput,
                    'companyCust': '',//companyCustFromInput,
                    'customerIDCust': '5054871',
                    'salesOrder': this.$store.state.configurations.salesOrder,
                    'salesOrderPos': this.$store.state.configurations.salesOrderPos
                }

                axios({
                    method: 'post',
                    url: '/api/summaries/request_offer_or_post_order_sap',
                    data: {
                        'data': data
                    }
                })
                    .then((response) => {
                        if (type == 'A') {
                            alert("Ihre Angebotsnummer lautet: " + response.data);

                        } else if (type == 'U') {
                            alert("Ihre Auftragsnummer lautet: " + response.data);
                        }
                    })
                //}
            },
            request_Quotation_or_post_Quotation_sap(type) {
                //var i = parseInt(this.inputCust.split('|')[0])
                //if (!Number.isNaN(i)) {
                //    //console.log(this.arrayCustomer);
                //    //console.log(this.inputCust.split('|')[1].trim());
                //    var customerIdFromInput = this.inputCust.split('|')[1].trim();
                //    //console.log(this.inputCust.split('|')[3].trim());
                //    var companyCustFromInput = this.inputCust.split('|')[3].trim();

                const data = {
                    'configurationOverviewId': this.$store.state.configurations.configurationOverviewsId,
                    'documentType': type,
                    'customerID': '',//customerIdFromInput,
                    'companyCust': '',//companyCustFromInput,
                    'customerIDCust': '5054871',
                    'salesOrder': this.$store.state.configurations.salesOrder,
                    'salesOrderPos': this.$store.state.configurations.salesOrderPos
                }

                axios({
                    method: 'post',
                    url: '/api/summaries/request_Quotation_or_post_Quotation_sap',
                    data: {
                        'data': data
                    }
                })
                    .then((response) => {
                        if (type == 'A') {
                            alert("Ihre Angebotsnummer lautet: " + response.data);

                        } else if (type == 'U') {
                            alert("Ihre Auftragsnummer lautet: " + response.data);
                        }
                    })
                //}
            },
        }
    }
</script>

<style>
    .navigation_fixed {
        position: fixed;
        top: 55px;
        z-index: 1000;
        margin-left: auto;
        margin-right: 0;
        width: 260px
    }

    .navigation_relative {
        position: relative;
    }
</style>