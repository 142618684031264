<template>
    <div class="modal-mask">
        <div class="modal-wrapper">
            <div class="modal-container">
                <div class="modal-header">
                    <h3>{{$t('3368')}}</h3>
                </div>
                <div class="modal-body">
                    <div>
                        <div class="my_font-weight_600" style="margin-bottom:10px">{{$t('3548')}}</div>
                        <div v-if="show_elevatorMotor_Machine==true">
                            <input type="checkbox" v-model="component_elevatorMotor_Machine" /><label style="margin-left:5px; margin-bottom: 10px" class="ZAfont_family">{{$t('3027')}}</label>
                        </div>
                        <div v-if="show_inverter==true">
                            <input type="checkbox" v-model="component_inverter" /><label style="margin-left:5px;margin-bottom:10px" class="ZAfont_family">{{$t('3091')}} ZAdyn</label>
                        </div>
                        <div v-if="show_powerRecuperationUnit==true">
                            <input type="checkbox" v-model="component_powerRecuperationUnit" /><label style="margin-left:5px; margin-bottom: 10px" class="ZAfont_family">{{$t('3163')}} ZArec</label>
                        </div>
                        <div v-if="show_brakeControl==true">
                            <input type="checkbox" v-model="component_brakeControl" /><label style="margin-left:5px; margin-bottom: 10px" class="ZAfont_family">{{$t('3198')}} ZAsbc</label>
                        </div>
                        <div v-if="show_frame==true">
                            <input type="checkbox" v-model="component_frame" /><label style="margin-left:5px; margin-bottom: 10px" class="ZAfont_family">{{$t('1025')}} ZAframe</label>
                        </div>
                    </div>
                    <div v-if="name==null" class="form-group">
                        <label for="name">{{$t('3360')}}</label>
                        <input type="text" id="name" v-model="name" class="form-control">
                    </div>
                    <div v-if="email==null" class="form-group">
                        <label for="email">{{$t('3361')}}</label>
                        <input type="email" id="email" v-model="email" class="form-control">
                    </div>
                    <div v-if="phone==null" class="form-group">
                        <label for="phone">{{$t('3362')}}</label>
                        <input type="tel" id="phone" v-model="phone" class="form-control">
                    </div>
                    <div class="form-group">
                        <label for="concern">{{$t('3363')}}</label>
                        <input type="text" id="concern" v-model="concern" class="form-control">
                    </div>
                    <div class="form-group">
                        <label for="concern">{{$t('3374')}}</label>
                        <select class="form-control"
                                v-model="erpNumber">
                            <option v-for="asset in assets"
                                    :value="asset">
                                {{asset}}
                            </option>
                        </select>
                        <!--{{ erpNumber }}-->
                    </div>
                    <div class="form-group">
                        <label for="content">{{$t('3364')}}</label>
                        <textarea id="content" v-model="content" class="form-control" style="height:120px"></textarea>
                    </div>
                    <div class="form-group">
                        <label for="content">{{$t('3375')}}:</label>
                        <!--<upload-files></upload-files>-->
                        <div>
                            <div v-if="currentFile" class="progress">
                                <div class="progress-bar progress-bar-info progress-bar-striped"
                                     role="progressbar"
                                     :aria-valuenow="progress"
                                     aria-valuemin="0"
                                     aria-valuemax="100"
                                     :style="{ width: progress + '%' }">
                                    {{ progress }}%
                                </div>
                            </div>

                            <label class="btn btn-default">
                                <input type="file" ref="file" @change="selectFile" />
                            </label>

                            <button class="btn btn-success" disabled>
                                Upload
                            </button>

                            <div class="alert alert-light" role="alert">{{ message }}</div>

                            <div class="card">
                                <div class="card-header">List of Files</div>
                                <ul class="list-group list-group-flush">
                                    <li class="list-group-item"
                                        v-for="(file, index) in fileInfos"
                                        :key="index">
                                        <a :href="file.url">{{ file.message }}</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button class="btn my_btn_standard_blue" @click="sendData">{{$t('3365')}}</button>
                    <!--<button class="btn btn-secondary" @click="$emit('close')">{{$t('3366')}}</button>-->
                    <button class="btn btn-secondary" @click="removeFiles">{{$t('3366')}}</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import axios from 'axios';
    import FormData from 'form-data';
    export default {
        props: {
            zlw: '',
            note: '',
        },
        data() {
            return {
                name: '',
                email: '',
                phone: '',
                concern: '',
                content: '',
                companyName: '',
                erpNumber: '',

                selected: null,
                data: null,
                assets: [],

                selectedFiles: undefined,
                currentFile: undefined,
                progress: 0,
                message: "",
                attachmentFiles: [],
                fileInfos: [],
                component_elevatorMotor_Machine: false,
                component_inverter: false,
                component_powerRecuperationUnit: false,
                component_brakeControl: false,
                component_frame: false
            }
        },
        beforeCreated() {
            this.removeFiles();
        },
        created() {

            /*            console.log("selections: ", this.assets);*/
            var jsonString = JSON.stringify(localStorage);
            var json = JSON.parse(jsonString);
            var keys = Object.keys(json);

            var key = '';
            for (var i = 0; i < keys.length; i++) {
                if (keys[i].includes('oidc.user')) {
                    key = keys[i];
                }
            }
            var token = JSON.parse(json[key]);
            var profile = token['profile'];
            this.name = profile['name'];
            this.email = profile['emails'][0];
            if (this.note != null && this.note != '') {
                this.concern = this.note;
            }
            else {
                this.concern = 'Anfrage zur Auslegung ' + this.zlw;
            }
            if (profile.hasOwnProperty('companyName')) {
                this.companyName = profile['companyName'];
            }
            if (profile.hasOwnProperty('mobilePhone')) {
                this.phone = profile['mobilePhone'];
            }
            if (profile.hasOwnProperty('customerRelations')) {
                var customerRelations = profile['customerRelations'];
                for (var i = 0; i < customerRelations.length; i++) {
                    this.assets.push(JSON.parse(customerRelations[i])['ERPNumber'])
                }
            }

        },
        computed: {
            show_elevatorMotor_Machine: {
                get() {
                    if (this.$store.state.summary.summary.elevatorMotor_Machine != null && this.$store.state.summary.summary.elevatorMotor_Machine.hasOwnProperty("blockNumberMachine") == true) {
                        return true;
                    }
                    else {
                        return false;
                    }
                }
            },
            show_inverter: {
                get() {
                    if (this.$store.state.summary.summary.inverter != null &&
                        this.$store.state.summary.summary.inverter.hasOwnProperty("typeDesignation") == true &&
                        this.$store.state.summary.summary.inverter.typeDesignation != null &&
                        this.$store.state.summary.summary.inverter.typeDesignation.indexOf("ZAdyn") > -1) {
                        return true;
                    }
                    else {
                        return false;
                    }
                }
            },
            show_powerRecuperationUnit: {
                get() {
                    if (this.$store.state.summary.summary.powerRecuperationUnit != null &&
                        this.$store.state.summary.summary.powerRecuperationUnit.hasOwnProperty("typeDesignation") == true &&
                        this.$store.state.summary.summary.powerRecuperationUnit.typeDesignation != null &&
                        this.$store.state.summary.summary.powerRecuperationUnit.typeDesignation.indexOf("ZArec") > -1) {
                        return true;
                    }
                    else {
                        return false;
                    }
                }
            },
            show_brakeControl: {
                get() {
                    if (this.$store.state.summary.summary.brakeControl != null &&
                        this.$store.state.summary.summary.brakeControl.hasOwnProperty("typeDesignation") == true &&
                        this.$store.state.summary.summary.brakeControl.typeDesignation != null &&
                        this.$store.state.summary.summary.brakeControl.typeDesignation.indexOf("ZAsbc") > -1) {
                        return true;
                    }
                    else {
                        return false;
                    }
                }
            },
            show_frame: {
                get() {
                    if (this.$store.state.summary.summary.frames != null && this.$store.state.summary.summary.frames.hasOwnProperty("blockNumber") == true) {
                        return true;
                    }
                    else {
                        return false;
                    }
                }
            },
        },
        methods: {

            async sendData() {
                if (this.erpNumber == '' && this.$store.state.account.user.profile.memberOf.includes('ZAEmployee') == false) {

                    alert('Customer number empty/Kundennummer ist leer.')
                    return;
                }

                this.$store.dispatch('summary/toDo_EmailSent_storeAction');

                const formData = new FormData();
                formData.append('name', this.name);
                formData.append('email', this.email);
                formData.append('subject', this.concern);
                formData.append('phone', this.phone);
                formData.append('content', this.content);
                formData.append('companyName', this.companyName);
                formData.append('erpNumber', this.erpNumber);
                formData.append('zlw', this.zlw);
                formData.append('elevatorMotor_Machine', this.component_elevatorMotor_Machine);
                formData.append('inverter', this.component_inverter);
                formData.append('powerRecuperationUnit', this.component_powerRecuperationUnit);
                formData.append('brakeControl', this.component_brakeControl);
                formData.append('frame', this.component_frame);
                formData.append('language', this.$store.state.configurations.language);

                for (let i = 0; i < this.attachmentFiles.length; i++) {
                    formData.append('attachments', this.attachmentFiles[i]);
                }
                //console.log(formData);
                await axios.post('/api/Summaries/sendemail', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data', // Important for sending file attachments
                    },
                })
                    .then(function (response) {
                        console.log("Email sent!");
                    })
                    .catch(function (error) {
                        console.log("Email failed!");
                    });
                this.$store.dispatch('summary/checkInformation_storeAction', this.inputCust);
                this.$emit('close');
            },
            async removeFiles() {
                this.attachmentFiles.splice(0, this.attachmentFiles.length);
                this.fileInfos.splice(0, this.fileInfos.length);
                this.$emit('close');
            },
            selectFile() {
                this.selectedFiles = this.$refs.file.files;
                this.upload();
            },

            upload() {
                this.progress = 0;
                this.currentFile = this.selectedFiles.item(0);
                this.attachmentFiles.push(this.currentFile);
                this.fileInfos.push({ message: this.currentFile.name });
                this.progress = 100;

                this.selectedFiles = undefined;
            }

        }
    }</script>

<style>
    .modal-mask {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(0, 0, 0, 0.5);
        display: flex;
        align-items: center;
        justify-content: center;
        overflow: scroll;
        /*        height: 120%;*/
    }

    .modal-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 650px;
    }

    .modal-container {
        background-color: white;
        border-radius: 10px;
        padding: 20px;
        max-width: 500px;
        width: 100%;
    }

    .modal-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 20px;
    }

        .modal-header h3 {
            margin: 0;
        }

    .modal-body {
        margin-bottom: 20px;
    }

    .modal-footer {
        display: flex;
        justify-content: flex-end;
    }
</style>
